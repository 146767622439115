import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserStore } from '../../../../../stores/user.store';
import { ErrorResponse } from '../../../flow-error/flow-error.component';

@Component({
  selector: 'app-point-history-data-wrapper',
  templateUrl: './point-history-data-wrapper.component.html',
  styleUrls: ['./point-history-data-wrapper.component.scss'],
})
export class PointHistoryDataWrapperComponent implements OnInit {
  formFilter: UntypedFormGroup;
  loadedMore = false;
  loaded = false;

  readonly userStore = inject(UserStore);
  globalError: ErrorResponse;

  constructor(private formBuilder: UntypedFormBuilder) {}

  async ngOnInit() {
    this.formFilter = this.formBuilder.group({
      showPurchases: [this.userStore.clubActions()?.filters?.showPurchases],
      showVouchers: [this.userStore.clubActions()?.filters?.showVouchers],
      showOthers: [this.userStore.clubActions()?.filters?.showOthers],
    });

    this.formFilter.controls.showPurchases.valueChanges.subscribe(async value => {
      await this.userStore.setClubActionsFilterShowPurchase(value);
      await this._getValues();
    });

    this.formFilter.controls.showVouchers.valueChanges.subscribe(async value => {
      await this.userStore.setClubActionsFilterShowVouchers(value);
      await this._getValues();
    });

    this.formFilter.controls.showOthers.valueChanges.subscribe(async value => {
      await this.userStore.setClubActionsFilterShowOthers(value);
      await this._getValues();
    });

    await this._resetDatatable();
  }

  async getMoreDatas() {
    if (this.userStore.clubActions().hasMore) await this._getValues();
  }

  private async _resetDatatable() {
    this.userStore.setClubActionsFilterShowOthers(true);
    this.userStore.setClubActionsFilterShowPurchase(true);
    this.userStore.setClubActionsFilterShowVouchers(true);
    await this._getValues();
  }

  private async _getValues() {
    this.globalError = undefined;

    try {
      this.loadedMore = false;
      await this.userStore.getClubActions(this.userStore.profile()?.id);
    } catch (err) {
      this.globalError = err;
    } finally {
      this.loadedMore = true;
      this.loaded = true;
    }
  }
}
