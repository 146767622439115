import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DateOnly } from '@microsoft/kiota-abstractions';
import { NewsletterSubscriptionSource } from '@victoria-company/agora-client';

@Injectable({
  providedIn: 'root',
})
export class ClubService {
  readonly api = inject(ApiService);

  public async getReferralData(userId: string) {
    const client = this.api.getClientInstance();
    return client.api.club.boosterInformation.get();
  }

  public async setBirthdate(birthdate: DateOnly) {
    const client = this.api.getClientInstance();
    return client.api.club.actions.setBirthdate.post({ birthdate });
  }

  public async createVoucherClub(amount: number) {
    const client = this.api.getClientInstance();
    return client.api.club.actions.vouchers.club.post({ amount });
  }

  public async getTrackableActions(contextId: number) {
    const client = this.api.getClientInstance();
    return client.api.club.trackableActionTypes.get({ queryParameters: { contextId } });
  }

  public async getClubActions(userId: string, pageIndex: number, pageSize: number, showPurchases: boolean, showVouchers: boolean, showOthers: boolean) {
    const client = this.api.getClientInstance();
    return client.api.club.actions.get({ queryParameters: { userId, pageIndex, pageSize, showPurchases, showOthers, showVouchers } });
  }

  public async getClubOrders(pageIndex: number, pageSize: number) {
    const client = this.api.getClientInstance();
    return client.api.user.orders.get({ queryParameters: { pageIndex, pageSize } });
  }

  public async getClubOrderById(id: string) {
    const client = this.api.getClientInstance();
    return client.api.user.orders.byOrderId(id).get();
  }

  public async followFacebook() {
    const client = this.api.getClientInstance();
    return client.api.club.actions.follow.facebook.post();
  }

  public async followInstagram() {
    const client = this.api.getClientInstance();
    return client.api.club.actions.follow.instagram.post();
  }

  public async subscribeNewsletter(email: string, locale: string, contextId: number, source: NewsletterSubscriptionSource = 'Club') {
    const client = this.api.getClientInstance();
    return client.api.newsletter.subscribe.post({ email, source, contextId, locale });
  }

  public async addClubCode(clubCode: string) {
    const client = this.api.getClientInstance();
    return client.api.club.actions.addCode.post({ clubCode });
  }

  public async getFinalizedCarts() {
    const client = this.api.getClientInstance();
    return client.api.user.finalizedCarts.get();
  }
}
