import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScreenService } from 'src/app/core/services/screen.service';
import { CheckoutType } from '../../../../core/enums/checkout-type.enum';
import { PaymentResponseStatus, PaymentResponseStatusObject, PaymentType, PaymentTypeObject } from '@victoria-company/agora-client';
import { CheckoutOrValidationProgressionStep } from '../../../../core/enums/checkoutOrValidationProgressionStep.enum';
import { ContextStore } from '../../../../stores/context.store';
import { ResourcesStore } from '../../../../stores/resources.store';
import { CheckoutPublicStore } from '../../../../stores/checkoutPublic.store';
import { ErrorResponse } from '../../../../shared/components/flow-error/flow-error.component';

@Component({
  selector: 'app-public-order-payment',
  templateUrl: './public-order-payment.component.html',
  styleUrls: ['./public-order-payment.component.scss'],
})
export class PublicOrderPaymentComponent implements OnInit {
  public resourcesStore = inject(ResourcesStore);
  public contextStore = inject(ContextStore);
  public checkoutPublicStore = inject(CheckoutPublicStore);
  public globalError: ErrorResponse;

  public paymentError: PaymentResponseStatus;
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    public screen: ScreenService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe(async params => {
      if (params.cartId) {
        this.globalError = undefined;
        const cartId = params.cartId;
        await this.checkoutPublicStore.loadCartById(cartId).catch(err => {
          this.globalError = err;
          this.checkoutPublicStore.setErrorOccurs();
        });
      }
    });

    this.activeRoute.queryParams.subscribe(params => {
      if (params.p) {
        this.paymentError = params.p;
        this.router.navigate([], {
          relativeTo: this.activeRoute,
          queryParams: { p: null },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  signDIP() {
    this.globalError = undefined;
    this.checkoutPublicStore.signDip(this.checkoutPublicStore.cart().id).catch(err => (this.globalError = err));
  }
  savePaymentType(paymentType: PaymentType) {
    this.checkoutPublicStore.setInternalPaymentType(paymentType);
  }
  proceedToPayment() {
    this.globalError = undefined;

    if (this.checkoutPublicStore.cart().totalToPay == 0) this.checkoutPublicStore.confirmCart(this.checkoutPublicStore.cart().id).catch(err => (this.globalError = err));
    else {
      const redirectUrl = `${this.checkoutPublicStore.internalPaymentType() == PaymentTypeObject.Digital ? window.origin : ''}/${this.contextStore.locale()}/public/cart/${this.checkoutPublicStore.cart().id}/checkout/confirmation`;
      this.checkoutPublicStore
        .proceedToPayment(this.checkoutPublicStore.cart().id, this.contextStore.locale(), this.checkoutPublicStore.internalPaymentType(), redirectUrl)
        .catch(err => (this.globalError = err));
    }
  }

  protected readonly CheckoutType = CheckoutType;
  protected readonly CheckoutOrValidationProgressionStep = CheckoutOrValidationProgressionStep;
  protected readonly PaymentTypeObject = PaymentTypeObject;
  protected readonly PaymentResponseStatusObject = PaymentResponseStatusObject;
}
