import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CartVoucherResponse } from '@victoria-company/agora-client';

@Component({
  selector: 'app-cart-voucher',
  templateUrl: './cart-voucher.component.html',
  styleUrls: ['./cart-voucher.component.scss'],
})
export class CartVoucherComponent {
  @Input() canDeleteVoucher: boolean = false;
  @Input() voucher: CartVoucherResponse;
  @Output() requestDeleteVoucher = new EventEmitter();

  deleteVoucher() {
    this.requestDeleteVoucher.emit();
  }
}
