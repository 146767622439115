import { Component, effect, inject } from '@angular/core';
import { UserStore } from '../../../../../../stores/user.store';
import { ContextStore } from '../../../../../../stores/context.store';
import { RGPDService } from '../../../../../../core/services/V2/rgpd.service';
import { GdprOptInSourceObject, GdprResponse } from '@victoria-company/agora-client';
import { ErrorResponse } from '../../../../../../shared/components/flow-error/flow-error.component';

@Component({
  selector: 'app-mycommunications',
  templateUrl: './mycommunications.component.html',
  styleUrls: ['./mycommunications.component.scss'],
})
export class MycommunicationsComponent {
  readonly contextStore = inject(ContextStore);

  readonly userStore = inject(UserStore);
  readonly rgpdService = inject(RGPDService);

  public noRGPDData = false;
  public globalError: ErrorResponse;

  public RGPDData: GdprResponse = {
    isOptIn: false,
    delegateConnections: [],
    isSubscribedCommercial: false,
    isSubscribedNewsletter: false,
  };

  constructor() {
    effect(async () => {
      if (this.userStore.hasProfile()) {
        try {
          this.RGPDData = await this.rgpdService.getRGPDataForClient();
          this.noRGPDData = false;
          this.globalError = undefined;
        } catch (err) {
          this.globalError = err;
          this.noRGPDData = true;
        }
      }
    });
  }

  async updateOptIn(value: boolean) {
    try {
      if (value) await this.rgpdService.setOptIn(GdprOptInSourceObject.Web);
      else await this.rgpdService.unsetOptIn();

      this.globalError = undefined;
    } catch (err) {
      this.globalError = err;
      this.RGPDData.isOptIn = !value;
    }
  }

  async updateDelegateRGPD(value: boolean, delegateId: string) {
    try {
      if (value) await this.rgpdService.setDelegateConnection(delegateId);
      else await this.rgpdService.unsetDelegateConnection(delegateId);

      this.noRGPDData = false;
      this.globalError = undefined;
    } catch (err) {
      this.globalError = err;
      const rgpdDelegate = this.RGPDData.delegateConnections.find(c => c.delegateId);
      if (rgpdDelegate) rgpdDelegate.isOptIn = !value;
    }
  }
}
