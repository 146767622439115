<div class="waiting-orders-list-data-wrapper-container">
  <div *ngIf="loaded">
    <app-finalized-carts-list-datas [carts]="userStore.finalizedCarts()" />
    <app-waiting-orders-list-datas [orders]="userStore.clubOrders()?.orders" [carts]="userStore.finalizedCarts()" />
    <div class="more">
      <div [ngClass]="loadedMore ? 'loading' : ''" (click)="getMoreDatas()" *ngIf="userStore.clubOrders()?.hasMore && !globalError">
        <p class="uppercase">{{ 'GLOBAL.ACTION.MORE' | translate }}</p>
        <svg viewBox="0 0 32 32">
          <use xlink:href="#icon-arrow-right"></use>
        </svg>
      </div>
    </div>
  </div>
  <div class="profile-main flex center-all loading" *ngIf="!loaded">
    <div class="loader"></div>
  </div>
</div>
<app-flow-error *ngIf="globalError" [error]="globalError" />
