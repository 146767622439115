import { Component, inject } from '@angular/core';
import { WishlistStore } from '../../../../stores/wishlist.store';
import { ErrorResponse } from '../../flow-error/flow-error.component';

@Component({
  selector: 'app-wishlist-delete-confirmation-modal',
  templateUrl: './wishlist-delete-confirmation-modal.component.html',
  styleUrls: ['./wishlist-delete-confirmation-modal.component.scss'],
})
export class WishlistDeleteConfirmationModal {
  public wishlistStore = inject(WishlistStore);
  globalError: ErrorResponse;

  cancelDeleteFromWishlist() {
    this.wishlistStore.cancelDeleteItemFromWishlistConfirmationModal();
  }

  async confirmDeleteFromWishlist(productId: string) {
    try {
      await this.wishlistStore.deleteItemFromWishlist(productId);
      this.globalError = undefined;
    } catch (err) {
      this.globalError = err;
    }
  }
}
