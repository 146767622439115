import { Component, inject, OnInit } from '@angular/core';
import { DocumentCategory, DocumentCategoryObject } from '@victoria-company/agora-client';
import { Router } from '@angular/router';
import { ContextStore } from '../../../../stores/context.store';
import { UserStore } from '../../../../stores/user.store';

@Component({
  selector: 'app-staff-manager',
  templateUrl: './staff-manager.component.html',
  styleUrls: ['./staff-manager.component.scss'],
})
export class StaffManagerComponent implements OnInit {
  private router = inject(Router);
  private contextStore = inject(ContextStore);
  readonly userStore = inject(UserStore);

  async ngOnInit() {
    if (!this.userStore.isDocumentsAdmin() && !this.userStore.isGuideAdmin()) {
      await this.router.navigate([`/${this.contextStore.locale()}/club/home`]);
    }
  }

  async goToManager(category: DocumentCategory) {
    await this.router.navigate([`/${this.contextStore.locale()}/club/manage/${category.toLowerCase()}`]);
  }

  protected readonly DocumentCategoryObject = DocumentCategoryObject;
}
