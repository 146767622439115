import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { UserService } from '../core/services/V2/user.service';
import { DemoService } from '../core/services/V2/demo.service';
import {
  CartSummaryResponse,
  ClubActionResponse,
  ClubCustomerActionTypesResponse,
  DelegateResponse,
  GetClubInformationResponse,
  OrderResponse,
  UpdateUserProfileRequest,
  UserProfileResponse,
  VoucherResponse,
} from '@victoria-company/agora-client';
import jwtDecode from 'jwt-decode';
import { CommonService } from '../core/services/V2/common.sevice';
import { NotificationStore } from './notification.store';
import { AlertTypes } from '../core/enums/alert-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { DateOnly } from '@microsoft/kiota-abstractions';
import { ClubService } from '../core/services/V2/club.service';
import { ContextStore } from './context.store';
import { getContextByUrl } from '../core/utils/filter.utils';
import { StorageService } from '../core/services/storage.service';

const transformSSORoles = (roles: string[], contextId: number) => {
  const appRoles = [];

  if (!contextId) contextId = getContextByUrl(window.origin);

  if (roles.includes('r_delegate')) appRoles.push('Delegate');
  if (roles.includes('r_client')) appRoles.push('Client');

  //Global Admin
  if (roles.includes('r_documents_commercial_admin')) appRoles.push('Documents_Admin');
  if (roles.includes('r_documents_hostessguide_admin')) appRoles.push('Guide_Admin');

  //Country Admin
  switch (contextId) {
    case 1:
      if (roles.includes('r_documents_commercial_admin_fr')) appRoles.push('Documents_Admin');
      if (roles.includes('r_documents_hostessguide_admin_fr')) appRoles.push('Guide_Admin');
      break;
    case 2:
      if (roles.includes('r_documents_commercial_admin_be')) appRoles.push('Documents_Admin');
      if (roles.includes('r_documents_hostessguide_admin_be')) appRoles.push('Guide_Admin');
      break;
    case 3:
      if (roles.includes('r_documents_commercial_admin_de')) appRoles.push('Documents_Admin');
      if (roles.includes('r_documents_hostessguide_admin_de')) appRoles.push('Guide_Admin');
      break;
  }

  return appRoles;
};

export interface UserState {
  init: boolean;
  isAuthenticated: boolean;
  roles: string[];
  connectedAsRole: string;
  profile: UserProfileResponse;
  clubInformation: GetClubInformationResponse;
  clubVouchers: {
    getUsed: boolean;
    vouchers: VoucherResponse[];
    pageIndex: number;
    pageSize: number;
    hasMore: boolean;
  };
  trackableActions: ClubCustomerActionTypesResponse[];
  clubActions: {
    actions: ClubActionResponse[];
    pageIndex: number;
    pageSize: number;
    hasMore: boolean;
    filters: {
      showPurchases: boolean;
      showVouchers: boolean;
      showOthers: boolean;
    };
  };
  finalizedCarts: CartSummaryResponse[];
  clubOrders: {
    orders: OrderResponse[];
    pageIndex: number;
    pageSize: number;
    hasMore: boolean;
  };
  clubOrder: OrderResponse;
  delegate: DelegateResponse;
  isProfileLoaded: boolean;
  userActiveDemoCode: string;
  userCode: string;
}

export const initialState: UserState = {
  init: false,
  userActiveDemoCode: null,
  profile: null,
  clubInformation: null,
  clubVouchers: {
    getUsed: true,
    vouchers: [],
    pageIndex: 0,
    pageSize: 20,
    hasMore: true,
  },
  trackableActions: [],
  clubActions: {
    actions: [],
    pageIndex: 0,
    pageSize: 20,
    hasMore: false,
    filters: {
      showPurchases: true,
      showVouchers: true,
      showOthers: true,
    },
  },
  finalizedCarts: [],
  clubOrders: {
    orders: [],
    pageIndex: 0,
    pageSize: 20,
    hasMore: true,
  },
  clubOrder: null,
  delegate: null,
  isProfileLoaded: false,
  isAuthenticated: null,
  roles: null,
  connectedAsRole: null,
  userCode: null,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withDevtools('user'),
  withState(initialState),
  withComputed(({ roles, profile }) => ({
    hasProfile: computed(() => profile() != null),
    hasRoles: computed(() => roles() != null),
    isDocumentsAdmin: computed(() => roles() && roles()?.includes('Documents_Admin')),
    isGuideAdmin: computed(() => roles() && roles()?.includes('Guide_Admin')),
  })),
  withMethods(
    (store, storage = inject(StorageService), commonService = inject(CommonService), userService = inject(UserService), demoService = inject(DemoService), clubService = inject(ClubService)) => ({
      async loadUserActiveDemoCode(): Promise<void> {
        const activeDemo = await demoService.getActiveDemo();
        patchState(store, () => ({
          userActiveDemoCode: activeDemo.activeDemo?.code,
        }));
      },
      async loadProfile(): Promise<void> {
        const profile = await userService.getProfile();

        if (profile) storage.setVictoriaProfile(profile);

        patchState(store, () => ({
          profile,
          isProfileLoaded: true,
        }));
      },
      async loadClubInformation(): Promise<void> {
        const clubInformation = await userService.getClubInformation();
        patchState(store, () => ({
          clubInformation,
        }));
      },
      async loadDelegate(): Promise<void> {
        if (store.hasProfile() && store.profile().assignedDelegateId) {
          const delegateProfileResponse = await commonService.getDelegateProfileAsPromise(store.profile().assignedDelegateId);
          patchState(store, { delegate: delegateProfileResponse });
        }
      },
      async loadUserCode(): Promise<void> {
        const userCode = await userService.getUserCode();
        patchState(store, { userCode });
      },
      //Not a computed bc connectedAsRole will evolve with role switch
      async setRolesFromToken(token: string, contextId: number): Promise<void> {
        const roles = token ? (jwtDecode(token) as { roles: string[] }).roles : [];

        patchState(store, () => ({
          roles: transformSSORoles(roles, contextId),
          connectedAsRole: roles.includes('r_delegate') ? 'Delegate' : 'Client',
        }));
      },
      async getTrackableActions(contextId: number) {
        const getActionsResponse = await clubService.getTrackableActions(contextId);
        if (getActionsResponse) {
          patchState(store, {
            trackableActions: getActionsResponse.customerActions,
          });
        }
      },
      async setClubActionsFilterShowPurchase(value: boolean) {
        patchState(store, {
          clubActions: {
            ...store.clubActions(),
            pageIndex: 0,
            filters: {
              ...store.clubActions().filters,
              showPurchases: value,
            },
          },
        });
      },
      async setClubActionsFilterShowVouchers(value: boolean) {
        patchState(store, {
          clubActions: {
            ...store.clubActions(),
            pageIndex: 0,
            filters: {
              ...store.clubActions().filters,
              showVouchers: value,
            },
          },
        });
      },
      async setClubActionsFilterShowOthers(value: boolean) {
        patchState(store, {
          clubActions: {
            ...store.clubActions(),
            pageIndex: 0,
            filters: {
              ...store.clubActions().filters,
              showOthers: value,
            },
          },
        });
      },
      async getClubActions(userId: string) {
        const getClubActionsResult = await clubService.getClubActions(
          userId,
          store.clubActions().pageIndex,
          store.clubActions().pageSize,
          store.clubActions().filters.showPurchases,
          store.clubActions().filters.showVouchers,
          store.clubActions().filters.showOthers
        );

        if (getClubActionsResult) {
          patchState(store, {
            clubActions: {
              ...store.clubActions(),
              actions: store.clubActions().pageIndex == 0 ? [...getClubActionsResult.actions] : [...store.clubActions().actions, ...getClubActionsResult.actions],
              pageIndex: getClubActionsResult.hasMore ? store.clubActions().pageIndex + 1 : store.clubActions().pageIndex,
              hasMore: getClubActionsResult.hasMore,
            },
          });
        }
      },
      async getClubOrders() {
        const getClubOrdersResult = await clubService.getClubOrders(store.clubOrders()?.pageIndex, store.clubOrders()?.pageSize);
        if (getClubOrdersResult) {
          patchState(store, {
            clubOrders: {
              ...store.clubOrders(),
              hasMore: getClubOrdersResult.hasMore,
              pageIndex: getClubOrdersResult.hasMore ? store.clubOrders()?.pageIndex + 1 : store.clubOrders()?.pageIndex,
              orders: store.clubOrders()?.pageIndex == 0 ? [...getClubOrdersResult.orders] : [...(store.clubOrders()?.orders ?? []), ...getClubOrdersResult.orders],
            },
          });
        }
      },
      async getFinalizedCarts() {
        const getFinalizedCartsResult = await clubService.getFinalizedCarts();
        if (getFinalizedCartsResult) {
          patchState(store, {
            finalizedCarts: getFinalizedCartsResult?.finalizedCarts,
          });
        }
      },
      async getClubOrderById(id: string) {
        if (store.clubOrder()?.id == id) return;

        const order = await clubService.getClubOrderById(id);
        patchState(store, {
          clubOrder: order ?? null,
        });
      },
    })
  ),
  withMethods(
    (
      store,
      contextStore = inject(ContextStore),
      userService = inject(UserService),
      clubService = inject(ClubService),
      translate = inject(TranslateService),
      notificationStore = inject(NotificationStore)
    ) => ({
      async setIsAuthenticated(isAuthenticated: boolean, token: string = undefined): Promise<void> {
        //prevent to execute multiple call when user already authenticated
        if (isAuthenticated && token) await store.setRolesFromToken(token, contextStore.contextId());
        if (store.isAuthenticated() && isAuthenticated) return;

        patchState(store, () => ({
          isAuthenticated: isAuthenticated,
        }));

        if (isAuthenticated) {
          await store.loadProfile().catch(err => console.log('Load Profile Error : ', err));
          await store.loadClubInformation().catch(err => console.log('Load ClubInformation Error : ', err));
          await store.loadDelegate().catch(err => console.log('Load Delegate Error : ', err));
          patchState(store, () => ({
            init: true,
          }));
        }
      },

      async updateProfile(request: UpdateUserProfileRequest): Promise<void> {
        await userService.updateProfile(request);

        notificationStore.notify({
          title: '',
          message: translate.instant('ALERT.PROFILE.UPDATED'),
          icon: '#icon-success',
          style: AlertTypes.SUCCESS,
        });
        store.loadProfile();
      },
      async addBirthdateToProfile(birthdate: Date, contextId: number) {
        const date: DateOnly = DateOnly.fromDate(birthdate);
        await clubService.setBirthdate(date);
        await store.loadClubInformation();
        await store.loadProfile();
        await store.getTrackableActions(contextId);
      },
      async createVoucherClubFromWallet(amount: number) {
        return await clubService.createVoucherClub(amount);
      },
      async getVouchers(pageIndex: number = 0, getUsed: boolean = true, pageSize: number = 20) {
        const result = await userService.getVouchers(pageIndex, pageSize, getUsed);

        patchState(store, {
          clubVouchers: {
            ...store.clubVouchers(),
            vouchers: result.pageIndex == 0 ? [...(result.vouchers ?? [])] : [...(store.clubVouchers()?.vouchers ?? []), ...(result.vouchers ?? [])],
            pageIndex: result.hasMore ? result.pageIndex + 1 : result.pageIndex,
            pageSize: result.pageSize,
            hasMore: result.hasMore,
            getUsed: getUsed,
          },
        });
      },
      async changeUserEmail(email: string) {
        await userService.updateUserEmail(email);
        patchState(store, {
          profile: {
            ...store.profile(),
            email,
          },
        });
      },
    })
  )
);
