<div class="cart-articles-container">
  <div class="cart-head-mobile m-only">
    <div class="cart-head-mobile-part">
      <div class="cart-head-mobile-heading">
        <div class="label label_default">{{ resources.mainTitle }}</div>
      </div>
      <div class="cart-head-mobile-badge">{{ cartStore.itemsCount() }}</div>
    </div>
    <div class="cart-head-mobile-part">
      <div class="cart-head-mobile-price">
        <div class="label label_default">{{ cartStore.cart()?.total | agoraCurrency }}</div>
      </div>
    </div>
  </div>
  <div id="cart-articles-main">
    <p>
      {{ resources.itemsFound }} <span class="art-qty">{{ cartStore.itemsCount() }} {{ resources.itemsLabel }}</span> {{ resources.itemsFoundSecond }}
    </p>
    <div class="item-list" *ngIf="resources && productsInCart()">
      <ng-template ngFor let-item [ngForOf]="cartStore.cart()?.cartItems" [ngForTrackBy]="trackByItems" let-i="index">
        <app-cart-item
          [product]="getProductInCartByVariantId(item.productVariantId)"
          [cartItem]="item"
          [demo]="cartStore.demo()"
          [productsInWishlist]="productsInWishlist()"
          (requestUpdateSize)="updateCartItemSize(item, $event)"
          (requestUpdateCartItemLinks)="updateCartItemLinks($event, item)"
          (requestAddRemoveFromWishlist)="addRemoveFromWishlist(item)"
          (requestAddItemToCart)="addToCart($event)"
          (requestRemoveFromCart)="removeFromCart(item)"
          (requestZoomInImage)="openZoomModal($event)"
          [classCondition]="i + 1 !== cartStore.itemsCount() ? 'border-bottom' : ''"></app-cart-item>
      </ng-template>

      <hr class="victoria-cart-separator" />
      <div class="cta-container d-width-fit" *ngIf="!hasBonusRemaining()">
        <button class="btn secondary" (click)="requestContinueShopping()">
          <label>{{ 'CART.CONTINUE.SHOPPING' | translate }}</label>
        </button>
      </div>
    </div>
  </div>
  <div class="cart-bottom-mobile" id="expand-cart">
    <div class="cart-bottom-mobile-button" (click)="displayArticle()">{{ resources.shippingButtonLabel }}</div>
  </div>
</div>

<app-confirmation-modal
  [title]="'CART.MODAL.CONFIRMATION' | translate"
  [width]="'42rem'"
  [isOpened]="cartStore.confirmDeleteItemFromCartModal().isOpened"
  (cancelClicked)="cancelDeleteFromCart()"
  (confirmClicked)="confirmDeleteFromCart()">
  <p class="confirmation-modal-content">{{ 'CART.MODAL.CONFIRMATION.CAPTION' | translate }}</p>
</app-confirmation-modal>
<app-zoom-in-picture-modal [opened]="zoomModalOpened" [imageUrl]="zoomModalImage" (requestCloseModal)="closeZoomModal()" />
<app-flow-error *ngIf="globalError" [error]="globalError" />
