import { Component, inject, Input } from '@angular/core';
import { CartSummaryResponse, OrderStatusObject } from '@victoria-company/agora-client';
import { ResourcesStore } from '../../../stores/resources.store';
import { GetDatasService } from '../../../core/services/get-datas.service';
import { toOrderStatusId } from '../../../core/utils/enumToInt';

@Component({
  selector: 'app-finalized-cart',
  templateUrl: './finalized-cart.component.html',
  styleUrls: ['./finalized-cart.component.scss'],
})
export class FinalizedCartComponent {
  @Input() cart: CartSummaryResponse;

  readonly resourcesStore = inject(ResourcesStore);
  readonly getDataService = inject(GetDatasService);
  protected readonly toOrderStatusId = toOrderStatusId;
  protected readonly OrderStatusObject = OrderStatusObject;
}
