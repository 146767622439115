import { DeliveryType, DeliveryTypeObject, DemoStatus, DemoStatusObject, OrderStatus, OrderStatusObject } from '@victoria-company/agora-client';

export const toDemoStatusInt = (status: DemoStatus) => {
  switch (status) {
    case DemoStatusObject.Created:
      return 0;
    case DemoStatusObject.PreSale:
      return 1;
    case DemoStatusObject.Opened:
      return 2;
    case DemoStatusObject.PostSale:
      return 3;
    case DemoStatusObject.Closed:
      return 4;
    default:
      return 0;
  }
};

export const toDeliveryId = (type: DeliveryType) => {
  switch (type) {
    case DeliveryTypeObject.ToDelegate:
      return 1;
    case DeliveryTypeObject.ToCustomer:
      return 10;
  }
};

export const toOrderStatusId = (status: OrderStatus) => {
  switch (status) {
    case OrderStatusObject.Open:
      return 1;
    case OrderStatusObject.InProcess:
      return 2;
    case OrderStatusObject.Published:
      return 3;
    case OrderStatusObject.Delivered:
      return 6;
    case OrderStatusObject.PartiallyDelivered:
      return 7;
    case OrderStatusObject.AwaitingBackOrders:
      return 8;
    case OrderStatusObject.Reimbursed:
      return 9;
    default:
      return 1;
  }
};
