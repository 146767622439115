import { Component, effect, inject, Input, signal } from '@angular/core';

import { CartStore } from '../../../../../stores/cart.store';
import { WishlistStore } from '../../../../../stores/wishlist.store';
import { derivedAsync } from 'ngxtension/derived-async';
import { DemoService } from '../../../../../core/services/V2/demo.service';
import { ScreenService } from '../../../../../core/services/screen.service';
import { CartResponse, DemoAttendeeResponse } from '@victoria-company/agora-client';
import { DemoStore } from '../../../../../stores/demo.store';
import { ErrorResponse } from '../../../flow-error/flow-error.component';

@Component({
  selector: 'app-modal-become-hostess-delegate',
  templateUrl: './modal-become-hostess-delegate.component.html',
  styleUrls: ['./modal-become-hostess-delegate.component.scss'],
})
export class ModalBecomeHostessDelegateComponent {
  @Input() cart: CartResponse;

  readonly demoService = inject(DemoService);
  readonly demoStore = inject(DemoStore);
  readonly screen = inject(ScreenService);
  readonly cartStore = inject(CartStore);
  readonly wishlistStore = inject(WishlistStore);

  public modalOpened = false;
  globalError: ErrorResponse;
  public id = 'become-delegate-modal';

  attendee = signal<DemoAttendeeResponse>(undefined);

  call = derivedAsync(async () => {
    if (this.cart && this.cart.demoId) {
      this.globalError = undefined;
      const demo = await this.demoService.getDemoByCodeOrId(this.cart.demoId).catch(err => (this.globalError = err));
      if (demo && demo.roles.length && demo.demo.status != 'Closed' && !this.globalError) {
        try {
          const response = await this.demoService.getConnectedDemoAttendee(this.cart.demoId);
          this.attendee.set(response);
        } catch (err) {
          this.globalError = err;
        }
      }
    }
  });

  constructor() {
    effect(() => {
      if (this.attendee() && this.wishlistStore.isLoaded() && (!this.attendee().requestedBecomeHostess || !this.attendee().requestedBecomeDelegate)) {
        this.triggerOpenModal();
      }
    });
  }

  async requestBecomeHostess() {
    try {
      await this.demoStore.requestBecomeHostess(this.cartStore.demo()?.demo?.code);
      const response = await this.demoService.getConnectedDemoAttendee(this.cart.demoId);
      this.attendee.set(response);
      this.globalError = undefined;
    } catch (err) {
      this.globalError = err;
    }
  }

  async requestBecomeDelegate() {
    try {
      await this.demoStore.requestBecomeDelegate(this.cartStore.demo()?.demo?.code);
      const response = await this.demoService.getConnectedDemoAttendee(this.cart.demoId);
      this.attendee.set(response);
      this.globalError = undefined;
    } catch (err) {
      this.globalError = err;
    }
  }

  triggerOpenModal() {
    window.setTimeout(() => {
      this.modalOpened = true;
    }, 3000);
  }

  closeModal() {
    this.modalOpened = false;
  }

  closeModalFromOutside(el: MouseEvent) {
    const m = el.target as HTMLElement;
    if (m.id === this.id && !this.screen.isMobile) {
      this.closeModal();
    }
  }
}
