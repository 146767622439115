import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { LoginGuard } from './config/login-guard.guard';
import { LanguageResolver } from './core/resolver/langFragmentResolver.service';
import { ErrorComponent } from './features/club-pages/components/error/error.component';
import { RedirectionComponent } from './features/public-pages/components/redirection/redirection.component';
import { AuthcallbackComponent } from './features/sso-pages/authcallback/authcallback.component';
import { LoginComponent } from './features/sso-pages/login/login.component';
import { LogoutComponent } from './features/sso-pages/logout/logout.component';
import { SupportComponent } from './features/support/support.component';

//ROUTING OPTION ---------------------------------------------------------------------
// breadcrumb : value  -- value to display in breadcrumb
// showBreadCrumb: false -- request to hide breadcrumb
// showJumbotron: false -- request to hide jumbo
// showNewsletter: false -- request to hide newsletter subscribtion frame
// showFooterMenu: false -- request to hide footer menu
// showFooterDisclaimer= false -- reqiest to hide footer disclaimer section
// showHeader: false -- switch header to header-light
// showHeaderMobileOnly: true -- Allows you to switch header to header-light except for mobile
// usePinkColor: true -- will switch main page + breadcrumb color to victoria pink
// enlargeScreen: true -- will extend screen limit
//------------------------------------------------------------------------------------
const routes: Routes = [
  {
    path: '',
    component: RedirectionComponent,
    data: {
      breadcrumb: 'GLOBAL.ORDER',
      showHeader: false,
      showHeaderMobileOnly: false,
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      usePinkColor: false,
      enlargeScreen: false,
    },
  },
  {
    path: 'support',
    component: SupportComponent,
    data: {
      breadcrumb: 'GLOBAL.ORDER',
      showHeader: false,
      showHeaderMobileOnly: false,
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
    },
  },
  {
    path: 'authCallback',
    component: AuthcallbackComponent,
    data: {
      breadcrumb: 'GLOBAL.ORDER',
      showHeader: false,
      showHeaderMobileOnly: false,
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
    },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      breadcrumb: 'GLOBAL.ORDER',
      showHeader: false,
      showHeaderMobileOnly: false,
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
    },
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: {
      breadcrumb: 'GLOBAL.ORDER',
      showHeader: false,
      showHeaderMobileOnly: true,
      showJumbotron: false,
      showBreadCrumb: false,
      showNewsletter: false,
      showFooterMenu: false,
      usePinkColor: true,
      enlargeScreen: true,
    },
  },
  {
    path: ':id/club',
    resolve: [LanguageResolver],
    canActivate: [AutoLoginPartialRoutesGuard],
    canActivateChild: [AutoLoginPartialRoutesGuard],
    // canActivate: [AuthorizationGuard],
    // canActivateChild: [AuthorizationGuard],
    loadChildren: () => import('./features/club-pages/club-pages.module').then(m => m.ClubPagesModule),
  },
  {
    path: ':id/public',
    resolve: [LanguageResolver],
    loadChildren: () => import('./features/public-pages/public-pages.module').then(m => m.PublicPagesModule),
  },
  {
    path: ':id/myvictoria',
    resolve: [LanguageResolver],
    data: {
      marginTop: true,
    },
    loadChildren: () => import('./features/agora-pages/agora-pages.module').then(m => m.AgoraPagesModule),
  },
  {
    path: ':id',
    component: LoginComponent,
    canActivate: [LoginGuard],
    resolve: [LanguageResolver],
    //For craft impl only ==>
    // redirectTo: 'public',
    // pathMatch: 'full',
    // <==
    data: {
      showBreadCrumb: false,
      showJumbotron: false,
      showNewsletter: false,
      showFooterMenu: false,
      usePinkColor: true,
    },
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

//RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading, scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload', useHash: false })
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading, scrollPositionRestoration: 'enabled', useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
