<div class="order-summary" *ngIf="resourcesStore.isLoaded() && cart?.id">
  <h1 *ngIf="hideProductList && !isReceipt">{{ 'DEMO.TUNNEL.AMOUNTTOPAY' | translate }}</h1>
  <h1 *ngIf="hideProductList && isReceipt">{{ 'DEMO.TUNNEL.BILL' | translate }}</h1>

  <app-order-cart [items]="cart.cartItems" *ngIf="!hideProductList"></app-order-cart>

  <div class="order-summary-footer">
    <div class="flex row j-space-between ai-center tr data-row">
      <div>
        @if (cart.isHostessCart) {
          {{ 'DEMO.SUMMARY.HOSTESSCART' | translate }}
        } @else {
          {{ resourcesStore.i18n().resources.shoppingBag.subtotal }}:
        }
      </div>
      <div class="amount">{{ cart.total | agoraCurrency }}</div>
    </div>
    <div class="flex column" *ngIf="cart.cartVouchers.length > 0">
      <div class="flex column voucher-block vouchers">
        <ng-template ngFor let-voucher [ngForOf]="cart.cartVouchers">
          <div class="voucher-container">
            <div class="flex row j-space-between ai-center tr lightbold">
              <div>{{ 'ORDER_TUNNEL.SUMMARY.VOUCHERS' | translate }}</div>
              <div class="amount">-{{ voucher.amount | agoraCurrency }}</div>
            </div>
            <app-cart-voucher [voucher]="voucher" [canDeleteVoucher]="canDeleteVouchersAndWallet" (requestDeleteVoucher)="deleteVoucher(voucher)"></app-cart-voucher>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="flex column data-row" *ngIf="cart.walletAmount > 0">
      <div class="flex row j-space-between ai-center tr lightbold">
        <div>{{ 'ORDER_TUNNEL.SUMMARY.WALLET' | translate }}</div>
        <div class="amount">&nbsp; - {{ cart.walletAmount | agoraCurrency }}</div>
      </div>
      <div *ngIf="canDeleteVouchersAndWallet" class="flex column voucher-block">
        <div class="cart-voucher flex ai center j-space-between">
          <div class="cart-voucher-code flex ai-center">
            {{ 'ORDER_TUNNEL.SUMMARY.WALLET.CANCEL' | translate }}
          </div>
          <div class="cart-voucher-action flex ai-center" (click)="deleteWallet()">
            <svg viewBox="0 0 32 32" class="icon trash">
              <use xlink:href="#icon-trash"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="flex row j-space-between ai-center tr data-row" *ngIf="!cart.isHostessCart">
      <div>{{ resourcesStore.i18n().resources.shoppingBag.shippingLabel }}:</div>
      <!--      No delivery-->
      <div *ngIf="!internalDeliveryData && !cart.delivery?.type">
        <a data-testId="delivery-fees-amount" (click)="openDeliveryInfoModal()">{{ resourcesStore.i18n().resources.shoppingBag.shippingButtonLabel }}</a>
      </div>
      <!--      Delivery from cart -->
      <div *ngIf="!internalDeliveryData && cart.delivery?.type == DeliveryTypeObject.ToDelegate">
        <a data-testId="delivery-fees-amount" (click)="openDeliveryInfoModal()">{{ 0 | agoraCurrency }}</a>
      </div>
      <div *ngIf="!internalDeliveryData && cart.delivery?.type == DeliveryTypeObject.ToCustomer">
        <a data-testId="delivery-fees-amount" (click)="openDeliveryInfoModal()">{{ cart.deliveryFee | agoraCurrency }}</a>
      </div>
      <!--      Internal delivery from store -->
      <div *ngIf="internalDeliveryData && internalDeliveryData.delivery?.type == DeliveryTypeObject.ToDelegate">
        <a data-testId="delivery-fees-amount" (click)="openDeliveryInfoModal()">{{ 0 | agoraCurrency }}</a>
      </div>
      <div *ngIf="internalDeliveryData && internalDeliveryData.delivery?.type == DeliveryTypeObject.ToCustomer">
        <a data-testId="delivery-fees-amount" (click)="openDeliveryInfoModal()">{{ internalDeliveryData.deliveryFee | agoraCurrency }}</a>
      </div>
    </div>
    <div class="flex row j-space-between ai-center tr data-row" *ngIf="cart.isHostessCart && cart.total != cart?.subTotal">
      <div>{{ resourcesStore.i18n().resources.shoppingBag.categoriesLabel }} :</div>
      <div class="amount">{{ cart?.subTotal | agoraCurrency }}</div>
    </div>
    <div class="flex row j-space-between ai-center tr data-row" *ngIf="cart?.isHostessCart">
      <div>{{ 'DEMO.SUMMARY.BONUS' | translate }}</div>
      <div class="amount">{{ -cart?.hostessBonus | agoraCurrency }}</div>
    </div>
    <div class="flex row j-space-between ai-center tr data-row" *ngIf="cart?.isHostessCart && hasBonusRemaining()">
      <div class="total recap">{{ 'DEMO.SUMMARY.AMOUNT.AVAILABLE' | translate }}</div>
      <div class="amount">
        {{ cart.hostessBonus - cart.subTotal | agoraCurrency }}
      </div>
    </div>
    <div class="flex row j-space-between ai-center tr data-row" *ngIf="!hasBonusRemaining() && cart?.isHostessCart && cart?.totalToPay >= 0">
      <div class="total recap">{{ 'DEMO.SUMMARY.AMOUNT.TOPAY' | translate }}</div>
      <div class="amount">
        {{ cart.totalToPay | agoraCurrency }}
      </div>
    </div>
    <div>
      <div class="flex row j-space-between ai-center tr" *ngIf="!cart.isHostessCart">
        <div class="total">{{ resourcesStore.i18n().resources.shoppingBag.categoriesLabel }}:</div>
        <div *ngIf="!internalDeliveryData" class="amount">{{ cart.totalToPay | agoraCurrency }}</div>
        <div *ngIf="internalDeliveryData" class="amount">{{ internalDeliveryData.totalToPay | agoraCurrency }}</div>
      </div>

      <div class="order-actions">
        <div class="free-amount" *ngIf="cart.cartItems.length > 0 && cart.exceedingVoucherAmount > 0">
          <div class="flex row j-space-between">
            <div>
              <div class="icon-circle flex center-all">
                <svg viewBox="0 0 32 32" class="icon billet">
                  <use xlink:href="#icon-bank-billet"></use>
                </svg>
              </div>
            </div>
            <p [innerHTML]="'ORDER_TUNNEL.SUMMARY.VOUCHERXGENERATED' | translate: { X: cart.exceedingVoucherAmount | agoraCurrency }"></p>
          </div>
        </div>
        <div *ngIf="step == CheckoutOrValidationProgressionStep.PAYMENT && cart.preContractualInformationDocument?.required && !cart.preContractualInformationDocument?.signed">
          <div class="payment-description warning flex row gap-10">
            <svg viewBox="0 0 32 32" class="icon billet">
              <use xlink:href="#icon-warning"></use>
            </svg>
            <p
              (click)="goToTheTop()"
              [innerHTML]="
                'ORDER_TUNNEL.SUMMARY.DIPCHECK'
                  | translate: { X: dataService.filter(resourcesStore.i18n().footer.footerBottomNav[2].navLink), Y: dataService.filter(resourcesStore.i18n().footer.footerBottomNav[1].navLink) }
              "></p>
          </div>
        </div>
        <div class="" *ngIf="showCTA">
          <button
            class="btn btn-primary j-center save-tunnel-button amount"
            *ngIf="displayButton"
            (click)="confirmStep()"
            [class.disabled]="operationPending || disableCTA"
            [disabled]="operationPending || disableCTA">
            <div *ngIf="operationPending" class="loader loader-mini"></div>
            {{ step == CheckoutOrValidationProgressionStep.PAYMENT ? ('ORDER_TUNNEL.SUMMARY.BUYNOW' | translate) : ('DEMO.TUNNEL.ACTION.VALIDATE' | translate) }}
          </button>
          <div *ngIf="step == CheckoutOrValidationProgressionStep.PAYMENT">
            <div class="payment-description">
              <p
                [innerHTML]="
                  'ORDER_TUNNEL.SUMMARY.CGV'
                    | translate: { X: dataService.filter(resourcesStore.i18n().footer.footerBottomNav[2].navLink), Y: dataService.filter(resourcesStore.i18n().footer.footerBottomNav[1].navLink) }
                "></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
