<div
  *ngIf="attendee() && wishlistStore.isLoaded()"
  class="agora-modal-container pop-up become-delegate"
  [style.display]="modalOpened ? 'flex' : 'none'"
  [id]="id"
  (click)="closeModalFromOutside($event)">
  <div class="modal">
    <div class="close">
      <svg viewBox="0 0 32 32" class="pointer" (click)="closeModal()">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <div class="scroll-zone">
      <div class="become-delegate-modal-container flex column">
        <div class="flex column center-all center">
          <h1 *ngIf="attendee() && !wishlistStore.hasItems()">{{ 'BECOMEDELEGATE.TITLE.NO.WISHLIST' | translate }}</h1>
          <h1 *ngIf="attendee() && wishlistStore.hasItems()">{{ 'BECOMEDELEGATE.TITLE.WISHLIST' | translate }}</h1>
        </div>
        <div class="flex column block-margin-top">
          <p class="center" *ngIf="wishlistStore.hasItems()" [innerHTML]="'BECOMEDELEGATE.CAPTION.HOTESSE' | translate"></p>
          <p class="center" *ngIf="!wishlistStore.hasItems()" [innerHTML]="'BECOMEDELEGATE.CAPTION.HOTESSE.NO.WISHLIST' | translate"></p>
          <div class="cta-container m-fw-btn" *ngIf="wishlistStore.hasItems()">
            <ag-button *ngIf="!attendee()?.requestedBecomeHostess" [label]="'BECOMEDELEGATE.ACTION.HOTESSE' | translate" (triggerFunction)="requestBecomeHostess()"></ag-button>
            <ag-button
              *ngIf="attendee()?.requestedBecomeHostess"
              [leftIcon]="'#icon-checkmark'"
              iconClasses="leftIcon"
              classes="btn-valid"
              [label]="'BECOMEDELEGATE.ACTION.HOTESSE.DONE' | translate"></ag-button>
          </div>
          <div class="cta-container m-fw-btn" *ngIf="!wishlistStore.hasItems()">
            <ag-button *ngIf="!attendee()?.requestedBecomeHostess" [label]="'BECOMEDELEGATE.ACTION.HOTESSE.NO.WISHLIST' | translate" (triggerFunction)="requestBecomeHostess()"></ag-button>
            <ag-button
              *ngIf="attendee()?.requestedBecomeHostess"
              [leftIcon]="'#icon-checkmark'"
              iconClasses="leftIcon"
              classes="btn-valid"
              [label]="'BECOMEDELEGATE.ACTION.HOTESSE.DONE' | translate"></ag-button>
          </div>
        </div>
        <div class="v-images-container flex row">
          <div class="image">
            <img src="/assets/images/join_victoria1.jpg" alt="image" />
          </div>
          <div class="image">
            <img src="/assets/images/join_victoria2.jpg" alt="image" />
          </div>
          <div class="image">
            <img src="/assets/images/join_victoria3.jpg" alt="image" />
          </div>
        </div>
        <div class="flex column">
          <p class="center" [innerHTML]="'BECOMEDELEGATE.CAPTION.DELEGATE' | translate"></p>
          <div class="cta-container m-fw-btn margin-bottom-8">
            <ag-button *ngIf="!attendee()?.requestedBecomeDelegate" [label]="'BECOMEDELEGATE.ACTION.DELEGATE' | translate" (triggerFunction)="requestBecomeDelegate()"></ag-button>
            <ag-button
              *ngIf="attendee()?.requestedBecomeDelegate"
              [leftIcon]="'#icon-checkmark'"
              iconClasses="leftIcon"
              classes="btn-valid"
              [label]="'BECOMEDELEGATE.ACTION.DELEGATE.DONE' | translate"></ag-button>
          </div>
        </div>
        <p class="sm-info">{{ 'BECOMEDELEGATE.SOCKETS.WARNING' | translate }}</p>
      </div>
    </div>
    <div class="scroll-icon">
      <svg>
        <use xlink:href="#icon-arrow-right"></use>
      </svg>
    </div>
  </div>
</div>
<app-flow-error *ngIf="globalError" [error]="globalError" />
