import { environment } from '../../../environments/environment';
import { CatalogService } from '../services/V2/catalog.service';
import { DemoHostessGifts } from '../../stores/demo.store';
import { ClubActionTypeEnumObject, ClubStatus, ClubStatusObject } from '@victoria-company/agora-client';
import { ClubActionTypeEnum } from '@victoria-company/agora-client/dist/models';
import { DemoService } from '../services/V2/demo.service';

export const getIntranetUrlByContext = (contextId: number) => {
  const url = environment.intranetUrlByContext[contextId];
  const production = environment.production;

  return production ? url.replace('{env}', 'intranet') : url.replace('{env}', 'acceptance');
};

export const getProductsFromGiftsResponse = async (gifts: DemoHostessGifts, locale: string, contextId: number, catalogService: CatalogService) => {
  const productIds = gifts?.giftsForCategories?.flatMap(gfc => gfc.choices)?.map(c => c.productId) ?? [];

  //Load products if has null
  if (productIds?.length && gifts?.giftsForCategories?.flatMap(gfc => gfc.choices?.some(c => c.product == null))) {
    const products = await catalogService.getProductsPromise(contextId, locale, productIds);
    gifts?.giftsForCategories.forEach(gfc =>
      gfc.choices?.forEach(c => {
        c.product = products?.products?.find(p => p.id == c.productId);
      })
    );
  }
};

export const getUserReferralLink = (contextId: number, referralRoute: string) => {
  let basePath = '';
  switch (contextId) {
    case 1:
      basePath += environment.base_url_france;
      break;
    case 2:
      basePath += environment.base_url_benelux;
      break;
    case 3:
      basePath += environment.base_url_allemagne;
      break;
  }
  return basePath + referralRoute;
};

export const getStatusIdFromClubStatus = (status: ClubStatus) => {
  switch (status) {
    case ClubStatusObject.Crystal:
      return 1;
    case ClubStatusObject.Opale:
      return 2;
    case ClubStatusObject.Jade:
      return 3;
    case ClubStatusObject.Amethyst:
      return 4;
  }
};

export const getTrackableActionTypeToLegacyText = (type: ClubActionTypeEnum) => {
  switch (type) {
    case ClubActionTypeEnumObject.CodeAdd:
      return 'ADD_PURCHASE';
    case ClubActionTypeEnumObject.FollowFacebook:
      return 'FOLLOW_FACEBOOK';
    case ClubActionTypeEnumObject.FollowInstagram:
      return 'FOLLOW_INSTAGRAM';
    case ClubActionTypeEnumObject.BirthdayAdd:
      return 'ADD_BIRTHDATE';
    case ClubActionTypeEnumObject.BoosterLevel1:
      return 'BOOSTER_LEVEL_1';
    case ClubActionTypeEnumObject.NewsletterSubscription:
      return 'SUBSCRIBE_NEWSLETTER';
  }
};

export const getTrackableActionTypeToLegacyId = (type: ClubActionTypeEnum) => {
  switch (type) {
    case ClubActionTypeEnumObject.CodeAdd:
      return 1;
    case ClubActionTypeEnumObject.BirthdayAdd:
      return 13;
    case ClubActionTypeEnumObject.NewsletterSubscription:
      return 12;
  }
};

export const getLocaleCodeFromLegacyLocaleByContext = (contextId: number, locale: string) => {
  switch (contextId) {
    case 1:
      return 'fr-fr';
    case 3:
      return 'de-de';
    case 2: {
      if (locale.toLowerCase().includes('fr')) return 'fr-be';
      else return 'nl-be';
    }
    default:
      return 'fr-fr';
  }
};

export const downloadDemoSummary = async (demoCode: string, locale: string, demoService: DemoService) => {
  const buffer = await demoService.getDemoPDFSummary(demoCode, locale);

  const blob = new Blob([buffer], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const newWindow = window.open(url, '_blank');

  if (!newWindow) {
    alert('Enable to Open the summary');
  }
};
