import { Component, effect, inject, Input, OnDestroy, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ModalService } from 'src/app/core/services/modal.service';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { Subscription } from 'rxjs';
import { ResourcesStore } from '../../../../../../stores/resources.store';
import { CartStore } from '../../../../../../stores/cart.store';
import { ContextStore } from '../../../../../../stores/context.store';
import { SaveGiftsRequest } from '../../../../../../shared/components/hostessGift/hostess-select-gifts/hostess-select-gifts.component';
import { GiftCategory } from '@victoria-company/agora-client';
import { Router } from '@angular/router';
import { CheckoutType } from '../../../../../../core/enums/checkout-type.enum';
import { ErrorResponse } from '../../../../../../shared/components/flow-error/flow-error.component';

@Component({
  selector: 'app-cart-content',
  templateUrl: './cart-content.component.html',
  styleUrls: ['./cart-content.component.scss'],
})
export class CartContentComponent implements OnDestroy {
  @Input() contextId: number;

  public resourcesStore = inject(ResourcesStore);
  public cartStore = inject(CartStore);
  public contextStore = inject(ContextStore);

  cartHasMultiVariants = signal(false);
  selectHostessGiftModalOpened = false;
  clickedGiftCard: GiftCategory;

  globalError: ErrorResponse;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    public modal: ModalService,
    public translation: TranslateService,
    public translate: TranslationService,
    public cookie: CookieService,
    public storage: StorageService
  ) {
    effect(async () => {
      if (this.cartStore.cart()?.isHostessCart) {
        try {
          this.globalError = undefined;
          await this.cartStore.getHostessGifts(this.cartStore.demo()?.demo?.code, this.contextStore.locale(), this.contextStore.contextId());
        } catch (err) {
          this.globalError = err;
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  openDeliveryInfoModal() {
    this.cartStore.openDeliveryInfo();
  }

  closeDeliveryInfoModal() {
    this.cartStore.closeDeliveryInfo();
  }

  openSelectHostessGiftModal($event?: GiftCategory) {
    this.clickedGiftCard = $event ?? this.cartStore.hostessGifts()?.giftsForCategories[0]?.category;
    this.selectHostessGiftModalOpened = true;
  }

  closeSelectHostessGiftModal() {
    this.selectHostessGiftModalOpened = false;
  }

  setHasMultiVariants(value: boolean) {
    this.cartHasMultiVariants.set(value);
  }

  async requestDelegateApproval() {
    try {
      this.globalError = undefined;
      await this.cartStore.requestDelegateApproval(this.cartStore.cart()?.id);
    } catch (err) {
      this.globalError = err;
    }
  }

  async saveHostessGift(gift: SaveGiftsRequest) {
    try {
      this.globalError = undefined;
      await this.cartStore.saveHostessGift(this.cartStore.demo()?.demo?.code, this.cartStore.cart()?.userId, gift);
    } catch (err) {
      this.globalError = err;
    }
  }

  async goToCollection() {
    if (this.cartStore.demo()?.demo && (this.cartStore.demo()?.demo?.status == 'Opened' || this.cartStore.demo()?.demo?.status == 'PostSale') && this.cartStore.demo()?.roles.includes('Attendee')) {
      await this.router.navigateByUrl(`/${this.contextStore.locale()}/myvictoria/demo/${this.cartStore.demo()?.demo?.code}/jewelry`);
    } else {
      window.location.href = this.resourcesStore.getEshopCollectionLink();
    }
  }

  protected readonly CheckoutType = CheckoutType;
}
