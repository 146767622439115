<div class="order-contact-container" *ngIf="delegate">
  <h1 class="canela">{{ resources.checkoutConsultant.secondTitle }}</h1>
  <p *ngIf="!cart.delegateId && !cart.demoId">
    {{
      resources.checkoutConsultant.proposedConsultantDescription
        | removeHtmlTag
        | translateWihParam
          : {
              name: profile.firstname,
              delegate: (delegate.displayName | uppercase)
            }
    }}
  </p>
  <p *ngIf="cart.delegateId && !cart.demoId">
    {{
      resources.checkoutConsultant.description
        | removeHtmlTag
        | translateWihParam
          : {
              name: profile.firstname | titlecase,
              delegate: delegate.displayName | uppercase
            }
    }}
  </p>
  <p *ngIf="cart.demoId">{{ resources.checkoutConsultant.homepartyDescription | removeHtmlTag | translateWihParam: { name: profile.firstname | titlecase } }}</p>
  <div class="flex column">
    <p class="subtitle" *ngIf="!cart.demoId">{{ resources.profileConsultant.description | removeHtmlTag }}</p>
    <p class="subtitle" *ngIf="cart.demoId">{{ resources.checkoutConsultant.homepartyLabelSecond | translateWihParam: { code: demo?.demo?.code } }}</p>
    <div class="order-contact-info flex row ai-center" [ngClass]="cart.demoId || cart.demoId ? 'hasDemo' : ''">
      <div class="img-container">
        <figure>
          <img loading="lazy" [src]="getProfilePictureOrDefault(delegate.profileImage)" [alternativeImage] />
        </figure>
      </div>
      <div class="teaser-container flex column flex-3 gap-10">
        <div class="header">{{ delegate.displayName | uppercase }}</div>
        <div class="location ai-center">
          <svg viewBox="0 0 32 32" class="icon peach">
            <use xlink:href="#icon-location"></use>
          </svg>
          {{ delegate.city | uppercase }}
        </div>
        <div class="teaser">{{ delegate.eShopPitch }}</div>
      </div>
    </div>
  </div>

  <!-- Sans Démo -->
  <div class="order-contact-footer flex j-space-between ai-center" *ngIf="!cart.demoId">
    <div>
      <button class="btn btn-primary j-center" [disabled]="cart.delegateId == delegate.id" [class.disabled]="cart.delegateId == delegate.id" (click)="confirmDelegate()">
        {{ resources.checkoutConsultant.continueButtonLabel }}
      </button>
    </div>
    <div>
      <a class="ctaLink" (click)="openSearchDelegate()">{{ resources.checkoutConsultant.chooseButtonLabel }}</a>
    </div>
  </div>
</div>
