import {
  CartResponse,
  GetCartAvailablePaymentTypesResponse_PaymentOption,
  GetDemoResponse,
  PaymentResponse,
  PaymentResponseStatus,
  PaymentResponseStatusObject,
  PaymentType,
  PaymentTypeObject,
} from '@victoria-company/agora-client';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { CartService } from '../core/services/V2/cart.service';
import { CheckoutService } from '../core/services/V2/checkout.service';
import { CommonService } from '../core/services/V2/common.sevice';
import { Router } from '@angular/router';
import { ContextStore } from './context.store';

export interface CheckoutPublicState {
  isLoaded: boolean;
  isReloading: boolean;
  hasError: boolean;
  cart: CartResponse;
  demo: GetDemoResponse;
  internalPaymentType: PaymentType;
  paymentOptions: GetCartAvailablePaymentTypesResponse_PaymentOption[];
  payment: PaymentResponse;
}

export const initialState: CheckoutPublicState = {
  cart: null,
  demo: null,
  internalPaymentType: PaymentTypeObject.Digital,
  paymentOptions: [],
  payment: null,
  isLoaded: false,
  isReloading: false,
  hasError: false,
};

export const CheckoutPublicStore = signalStore(
  { providedIn: 'root' },
  withDevtools('checkout public'),
  withState(initialState),
  withComputed(store => ({
    mustSignDIP: computed(() => store.cart()?.status == 'Active' && store.cart()?.preContractualInformationDocument.required),
    disableCTA: computed(() => !store.cart()?.preContractualInformationDocument?.signed || !store.internalPaymentType()),
    isValidLink: computed(() => getLinkValidityState(store.cart(), store.demo(), store.payment()?.status)),
  })),
  withMethods((store, contextStore = inject(ContextStore), cartService = inject(CartService)) => ({
    async loadCartById(cartId: string, loadCartOnly = false): Promise<void> {
      patchState(store, () => ({ isReloading: loadCartOnly }));

      const cart: CartResponse = await cartService.getCartByCartId(cartId);

      if (!cart) {
        patchState(store, () => ({ isReloading: false, hasError: true }));
        return;
      }

      if (loadCartOnly) {
        patchState(store, () => ({
          cart: {
            ...cart,
          },
        }));
      } else {
        const demoResponse = await cartService.getCartDemo(cart.demoId);
        const paymentsOptions = await cartService.getPaymentOptions(cart.id, contextStore.locale());

        if (cart && cart.status != 'Active') paymentsOptions.paymentOptions = paymentsOptions.paymentOptions.filter(p => p.paymentType != 'HandledByDelegate');

        let payments: PaymentResponse = null;

        const sortedPayments =
          cart?.cartPayments
            .filter(p => p.type == 'Digital')
            .sort((a, b) => {
              if (a.creationDate > b.creationDate) return -1;
              else return 0;
            }) ?? [];

        if (sortedPayments[0]?.paymentId) {
          payments = await cartService.getCartPayment(sortedPayments[0].paymentId);
        }

        patchState(store, () => ({
          isReloading: false,
          isLoaded: true,
          hasError: false,
          cart: {
            ...cart,
          },
          demo: demoResponse,
          paymentOptions: paymentsOptions.paymentOptions,
          payment: payments,
        }));
      }
    },
    setErrorOccurs() {
      patchState(store, { isLoaded: true, hasError: true });
    },
  })),
  withMethods((store, cartService = inject(CartService), contextStore = inject(ContextStore), router = inject(Router), checkoutService = inject(CheckoutService), commonService = inject(CommonService)) => ({
    async signDip(cartId: string): Promise<void> {
      await checkoutService.postSignDipPromise(cartId);
      await store.loadCartById(cartId, true);
    },
    async confirmCart(cartId: string): Promise<void> {
      //Reload data to ensure validity before trigger payment process
      const cart = await cartService.getCartByCartId(cartId)

      if (!cart) {
        patchState(store, () => ({ isReloading: false, hasError: true }));
        return;
      }

      const demoResponse = await cartService.getCartDemo(cart?.demoId);
      let payments: PaymentResponse = null;

      const sortedPayments =
        cart?.cartPayments
          .filter(p => p.type == 'Digital')
          .sort((a, b) => {
            if (a.creationDate > b.creationDate) return -1;
            else return 0;
          }) ?? [];

      if (sortedPayments[0]?.paymentId) {
        payments = await cartService.getCartPayment(sortedPayments[0].paymentId);
      }

      patchState(store, () => ({
        cart: {
          ...cart,
        },
        demo: demoResponse,
        payment: payments,
      }));
      //End Reload Datas

      if(store.cart().totalToPay == 0) {
        await checkoutService.postConfirmCartPromise(cartId);
        await router.navigate([`/${contextStore.locale()}/public/checkout/${cartId}/confirmation`]);
      }
    },
    async proceedToPayment(cartId: string, locale: string, paymentType: PaymentType, redirectUrl: string): Promise<void> {
      //Reload data to ensure validity before trigger payment process
      const cart = await cartService.getCartByCartId(cartId)

      if (!cart) {
        patchState(store, () => ({ isReloading: false, hasError: true }));
        return;
      }

      const demoResponse = await cartService.getCartDemo(cart?.demoId);
      let payments: PaymentResponse = null;

      const sortedPayments =
        cart?.cartPayments
          .filter(p => p.type == 'Digital')
          .sort((a, b) => {
            if (a.creationDate > b.creationDate) return -1;
            else return 0;
          }) ?? [];

      if (sortedPayments[0]?.paymentId) {
        payments = await cartService.getCartPayment(sortedPayments[0].paymentId);
      }

      patchState(store, () => ({
        cart: {
          ...cart,
        },
        demo: demoResponse,
        payment: payments,
      }));
      //End Reload Datas

      //If Open Digital Payment exists with Checkout URL, redirect Use to the payment
      if(store.payment() && store.payment().type=="Digital" && store.payment().status=="Open" && store.payment()?.checkoutUrl){
        window.location.href = store.payment().checkoutUrl;
      } else {
        //Handle Payment Request
        const response = await commonService.postPaymentPromise(cartId, locale, paymentType, redirectUrl);
        if (response.checkoutUrl != null) window.location.href = response.checkoutUrl;
        else await router.navigateByUrl(redirectUrl);
      }
    },
    setInternalPaymentType(paymentType: PaymentType) {
      patchState(store, () => ({
        internalPaymentType: paymentType,
      }));
    },
  }))
);

const getLinkValidityState = (cart: CartResponse, demo: GetDemoResponse, paymentStatus: PaymentResponseStatus) => {
  if (!cart || !demo.demo) return false;

  if ((cart.status != 'Finalized' && cart.status != 'Active' && cart.status != 'Confirmed') || paymentStatus == PaymentResponseStatusObject.Paid) return false;
  else return true;
};
