import { ActivatedRoute } from '@angular/router';
import { Component, inject, OnInit } from '@angular/core';
import { getRootUrl } from 'src/app/core/utils/filter.utils';
import { StorageService } from 'src/app/core/services/storage.service';
import { ContextStore } from '../../../stores/context.store';
import { CommonService } from '../../../core/services/V2/common.sevice';
import { DelegateResponse } from '@victoria-company/agora-client';
// import { getLocaleCodeFromLegacyLocaleByContext } from '../../../core/utils/common.utils';

@Component({
  selector: 'app-esolanding',
  templateUrl: './eso-landing.component.html',
  styleUrls: ['./eso-landing.component.scss'],
})
export class EsoLandingComponent implements OnInit {
  private storedDelegateCode: string;
  private storedDemoId: string;

  readonly contextStore = inject(ContextStore);
  readonly commonService = inject(CommonService);

  constructor(
    private activatedRoute: ActivatedRoute,
    private storage: StorageService
  ) {}

  async ngOnInit() {
    const delegateCode = this.activatedRoute.snapshot.params?.delegateId;
    let isMatchingDelegateCode = false;

    this.storedDelegateCode = this.storage.delegateCode;
    this.storedDemoId = this.storage.demoId;

    if (!delegateCode) {
      this.redirectToSite();
      return;
    }

    //CHECK STORED DELEGATE
    try {
      if (this.storedDelegateCode) {
        await this.commonService.getDelegateByCode(this.storedDelegateCode);
      }
    } catch (err) {
      const errorCode = err?.responseStatusCode || err?.response?.data?.errorCode || err?.errorCode || err?.code;

      //404 if user doesn't exists
      if (errorCode == 404) {
        this.storedDelegateCode = null;
        this.storedDemoId = null;

        this.storage.removeDelegateCode();
        this.storage.removeDelegateId();
        this.storage.removeDemoId();
      }
    }

    //CHECK REQUESTED DELEGATE
    try {
      const delegate = await this.commonService.getDelegateByCode(delegateCode);

      if (!delegate) {
        this.redirectToSite();
        return;
      }

      isMatchingDelegateCode = this.storedDelegateCode && (this.storedDelegateCode == delegate.eShopAlias || this.storedDelegateCode == delegate.eShopId);
      this.validateDelegate(delegate, isMatchingDelegateCode);
    } catch (err) {
      console.log('Eso landing exception: ', err);
    } finally {
      this.redirectToSite();
    }
  }

  private validateDelegate(delegate: DelegateResponse, preserveCookie = false) {
    this.storage.delegateId = delegate.id;
    this.storage.delegateCode = delegate.eShopId;

    if (this.storedDemoId && !preserveCookie) this.storage.removeDemoId();
    // Desactivated to preserve locale From eso link
    // if (delegate.languages[0] && getLocaleCodeFromLegacyLocaleByContext(this.contextStore.contextId(), delegate.languages[0]) != this.contextStore.locale())
    //   this.contextStore.setLocale({
    //     locale: getLocaleCodeFromLegacyLocaleByContext(this.contextStore.contextId(), delegate.languages[0]),
    //     region: getLocaleCodeFromLegacyLocaleByContext(this.contextStore.contextId(), delegate.languages[0]),
    //   });
  }

  private redirectToSite() {
    window.location.href = getRootUrl(this.contextStore.locale());
  }
}
