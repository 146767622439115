import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/app/core/services/storage.service';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { getSSOLocale } from 'src/app/core/utils/filter.utils';
import { environment } from 'src/environments/environment';
import { ResourcesStore } from '../../../stores/resources.store';
import { ContextStore } from '../../../stores/context.store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  registerURL = '#';
  isSandbox = environment.isSandbox;

  resourcesStore = inject(ResourcesStore);
  contextStore = inject(ContextStore);

  @Input() isComponent = false;
  @Input() redirectUri;

  @Output() requestRegistration: EventEmitter<any> = new EventEmitter();

  constructor(
    private trigger: TriggerService,
    public storage: StorageService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.redirectUri) this.storage.redirectTo = this.redirectUri;
  }

  public login() {
    this.trigger.requestLogin();
  }

  public async goToRegistration() {
    if (!this.isSandbox) {
      const redirect_URI = this.getRegisterRedirectURIFromContextId(this.contextStore.contextId());
      if (!redirect_URI) return;

      this.registerURL = `https://connect.victoria-company.com/oauth2/register?client_id=1ba11db4-a6ee-43d7-a896-a7dc27c81e8e&locale=${getSSOLocale(this.storage.languageCode ?? this.contextStore.locale())}&response_type=code&redirect_uri=${redirect_URI}`;
      location.href = this.registerURL;
    } else {
      alert(this.translate.instant('GLOBAL.UNAUTHORIZED.SANDBOX'));
    }
  }

  private getRegisterRedirectURIFromContextId(contextId: number) {
    switch (contextId) {
      case 1:
        return environment.eshop_url_france;
      case 2:
        return environment.eshop_url_benelux;
      case 3:
        return environment.eshop_url_allemagne;
      default:
        return undefined;
    }
  }
}
