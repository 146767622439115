import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

export enum AttachmentType {
  FILE,
  IMAGE,
}
@Component({
  selector: 'app-file-dropzone',
  templateUrl: './file-dropzone.component.html',
  styleUrls: ['./file-dropzone.component.scss'],
})
export class FileDropzoneComponent implements OnInit {
  @Input() dropzoneText: string = ''; //Text to display in the dropzone
  @Input() classes: string = ''; //Custom Classes For the component
  @Input() style: string = ''; //Custom inline style For the component
  @Input() allowMultiple = false; //Component Allows multiple file upload
  @Input() attachmentSrc: string;
  @Input() attachmentName: string;
  @Input() type: AttachmentType;

  @Output() requestUploadFile = new EventEmitter<File>();
  @Output() requestRemoveAttachment = new EventEmitter();

  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;

  droppedFile: File;
  filePreview: string | ArrayBuffer | null = null; // Variable pour stocker la prévisualisation de l'image

  ngOnInit() {
    if (this.attachmentSrc) {
      const explodedSrc = this.attachmentSrc.split('/');
      const nameIndex = explodedSrc.length - 1;
      this.attachmentName = explodedSrc[nameIndex];
    }
  }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer?.files;
    this.handleFiles(files);
  }
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer!.dropEffect = 'copy';
  }
  dropzoneClicked(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.allowMultiple || !this.droppedFile) this.fileInput.nativeElement.click();
  }

  uploadedFileChanged(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input?.files;
    this.handleFiles(files);
  }

  previewImage(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.filePreview = reader.result;
    };

    // Lire le contenu du fichier comme une URL Data
    reader.readAsDataURL(file);
  }

  deleteFile(event) {
    event.preventDefault();
    event.stopPropagation();

    this.droppedFile = undefined;
    this.filePreview = undefined;
    this.attachmentSrc = undefined;
    this.attachmentName = undefined;
    this.requestRemoveAttachment.emit();
  }

  private handleFiles(files: FileList, type = 'image/') {
    if (files && files.length > 0) {
      const file = files[0];
      this.droppedFile = file;

      if (file.type.startsWith(type)) {
        this.previewImage(file);
      }

      this.requestUploadFile.emit(file);
    }
  }

  protected readonly AttachmentType = AttachmentType;
}
