<div class="point-history-wrapper-container">
  <h2 *ngIf="getStatusIdFromClubStatus(clubInformation.clubStatus) < 4" class="table-title">
    {{ 'STATUS.TITLE.GOTOSTATUSX' | translate: { X: 'CLIENT.STATUS' + (getStatusIdFromClubStatus(clubInformation.clubStatus) + 1) | translate } }}
  </h2>
  <h2 *ngIf="getStatusIdFromClubStatus(clubInformation.clubStatus) === 4" class="table-title">
    {{ 'STATUS.TITLE.KEEPSTATUSX' | translate: { X: 'CLIENT.STATUS' + getStatusIdFromClubStatus(clubInformation.clubStatus) | translate } }}
  </h2>
  <app-status-datas *ngIf="userStore.trackableActions().length || globalError" [clubInformation]="clubInformation" [trackableActions]="userStore.trackableActions()" />
  <div class="flex center-all loading" *ngIf="!globalError && !userStore.trackableActions().length">
    <div class="loader"></div>
  </div>
</div>
<app-flow-error *ngIf="globalError" [error]="globalError" />
