import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomainException } from '../../../features/agora-pages/demo/enums/exceptions.enum';
import { NotificationStore } from '../../../stores/notification.store';
import { TranslateService } from '@ngx-translate/core';
import { NotificationIcon, NotificationStyle } from '../../../core/types/notification.options.type';
import { ContextStore } from '../../../stores/context.store';
import { CartStore } from '../../../stores/cart.store';

export type ErrorResponse = {
  responseStatusCode?: number;
  domainExceptionName?: DomainException;
  code?: number;
  errorCode?: number;
  response?: {
    data: {
      errorCode: number;
    };
  };
};

@Component({
  selector: 'app-flow-error',
  templateUrl: './flow-error.component.html',
  styleUrls: ['./flow-error.component.scss'],
})
export class FlowErrorComponent implements OnInit, OnChanges {
  // If showError=true, error label will be visible.
  // Otherwise, a toast is shown to the user
  @Input() style: string;
  @Input() showError = false;
  @Input() error: ErrorResponse;

  domainError: DomainException = DomainException.ServerError;

  readonly notificationStore = inject(NotificationStore);
  readonly contextStore = inject(ContextStore);
  readonly translate = inject(TranslateService);

  //Needed for special errors handling
  readonly cartStore = inject(CartStore);

  ngOnInit() {
    this.handleError();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['error'] && changes['error'].currentValue) {
      this.handleError();
    }
  }

  private handleError() {
    const errorCode = this.error?.responseStatusCode || this.error?.response?.data?.errorCode || this.error?.errorCode || this.error?.code;
    const domainError = this.error?.domainExceptionName;

    switch (errorCode) {
      case 400:
        this.domainError = DomainException.BadRequest;
        break;
      case 401:
      case 403:
        this.domainError = DomainException.Unauthorized;
        break;
      case 404:
        this.domainError = DomainException.NotFound;
        break;
      case 422:
        this.domainError = domainError;
        break;
      default:
        this.domainError = DomainException.ServerError;
    }

    this.domainError = this.getGenericErrorKeyIfNeeded(this.domainError);

    if (!this.showError) {
      this.notificationStore.notify({
        message: this.translate.instant('DOMAIN.ERROR.' + (this.domainError?.toUpperCase() ?? 'GENERIC')),
        style: NotificationStyle.DANGER,
        icon: NotificationIcon.WARNING,
      });
    }
  }
  private getGenericErrorKeyIfNeeded(key: DomainException) {
    const translation = this.translate.instant('DOMAIN.ERROR.' + (key?.toUpperCase() ?? 'GENERIC'));
    if (translation == 'DOMAIN.ERROR.' + (key?.toUpperCase() ?? 'GENERIC')) return DomainException.Generic;
    else return key;
  }
}
