import { Component, Input } from '@angular/core';
import { getProfilePictureOrDefault } from '../../../../../../core/utils/filter.utils';
import {DelegateResponse} from "@victoria-company/agora-client";

@Component({
  selector: 'app-order-demo-delegate',
  templateUrl: './order-demo-delegate.component.html',
  styleUrls: ['./order-demo-delegate.component.scss'],
})
export class OrderDemoDelegateComponent {
  @Input() delegate: DelegateResponse;
  @Input() noMarginTop = false;

  constructor() {}

  protected readonly getProfilePictureOrDefault = getProfilePictureOrDefault;
}
