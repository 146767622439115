import { Component, inject, Input, OnInit } from '@angular/core';
import { UserStore } from '../../../../../stores/user.store';
import { ErrorResponse } from '../../../flow-error/flow-error.component';

@Component({
  selector: 'app-waiting-orders-list-data-wrapper',
  templateUrl: './waiting-orders-list-data-wrapper.component.html',
  styleUrls: ['./waiting-orders-list-data-wrapper.component.scss'],
})
export class WaitingOrdersListDataWrapperComponent implements OnInit {
  @Input() datatableTitle: string;
  readonly userStore = inject(UserStore);

  loaded = false;
  loadedMore = false;
  globalError: ErrorResponse;

  async ngOnInit() {
    await this._getValues();
    this.loaded = true;
  }

  async getMoreDatas() {
    await this._getValues();
  }

  private async _getValues() {
    this.globalError = undefined;
    this.loadedMore = true;

    await this.userStore.getFinalizedCarts().catch(err => this.globalError);
    await this.userStore.getClubOrders().catch(err => (this.globalError = err));

    this.loadedMore = false;
  }
}
