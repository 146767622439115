import { Component, computed, inject, OnInit, Signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentsStore } from '../../../../../../stores/documents.store';
import { DocumentCategoryObject, DocumentResponse } from '@victoria-company/agora-client';
import { ContextStore } from '../../../../../../stores/context.store';
import { TranslateService } from '@ngx-translate/core';
import { UserStore } from '../../../../../../stores/user.store';
import { ErrorResponse } from '../../../../../../shared/components/flow-error/flow-error.component';

@Component({
  selector: 'app-documents-listing',
  templateUrl: './documents-listing.component.html',
  styleUrls: ['./documents-listing.component.scss'],
})
export class DocumentsListingComponent implements OnInit {
  readonly active = inject(ActivatedRoute);
  readonly documentsStore = inject(DocumentsStore);
  readonly userStore = inject(UserStore);
  readonly contextStore = inject(ContextStore);
  readonly router = inject(Router);
  readonly translate = inject(TranslateService);

  category: string = DocumentCategoryObject.CommercialAction.toUpperCase();
  documents: Signal<DocumentResponse[]> = computed(() => {
    switch (this.category) {
      case DocumentCategoryObject.CommercialAction.toUpperCase():
        return this.documentsStore.commercialActionsForStaff();
      case DocumentCategoryObject.HostessGuide.toUpperCase():
        return this.documentsStore.hostessActionsForStaff();
    }
  });

  public globalError: ErrorResponse;

  //MODAL
  deleteConfirmationModalOpened = false;
  actionsSheetOpened = false;
  currentDocument: DocumentResponse;

  constructor() {}

  async ngOnInit() {
    this.globalError = undefined;

    if (!this.userStore.isDocumentsAdmin() && !this.userStore.isGuideAdmin()) {
      await this.router.navigate([`/${this.contextStore.locale()}/club/home`]);
      return;
    }

    this.active.params.subscribe(params => {
      if (params.category) this.category = params.category.toUpperCase();
    });

    if (!this.documentsStore.staffDocuments()) await this.documentsStore.loadDocumentsForStaff().catch(err => (this.globalError = err));
  }

  openActionSheet(document: DocumentResponse) {
    this.currentDocument = document;
    this.actionsSheetOpened = true;
  }

  closeActionSheet() {
    this.actionsSheetOpened = false;
  }

  openDeleteDocumentModal(document: DocumentResponse) {
    this.currentDocument = document;
    this.actionsSheetOpened = false;
    this.deleteConfirmationModalOpened = true;
  }

  closeDeleteDocumentModal() {
    this.currentDocument = undefined;
    this.deleteConfirmationModalOpened = false;
  }

  isVisibleDocument(start: Date, end: Date) {
    const now = new Date();
    return start <= now && now <= end;
  }

  async deleteDocument() {
    try {
      await this.documentsStore.deleteDocumentById(this.currentDocument?.id);
      this.globalError = undefined;
      this.closeDeleteDocumentModal();
    } catch (err) {
      this.globalError = err;
    }
  }

  async navigateTo(action: string, id?: string) {
    let route = `/${this.contextStore.locale()}/club/manage/${this.category.toLowerCase()}`;

    if (id) {
      route += `/${action}/${id}`;
      await this.router.navigate([route]);
    } else await this.router.navigate([route, action]);
  }

  getDeleteDocumentMessage() {
    return this.translate.instant('DOCUMENT.MANAGER.DELETE.CAPTION', { X: this.currentDocument?.title });
  }
}
