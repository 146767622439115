import { Component, effect, EventEmitter, inject, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../../../core/services/translation.service';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from '../../../../core/services/storage.service';
import { Router } from '@angular/router';
import { OrderActionEnum } from '../../../../features/agora-pages/demo/enums/order-action.enum';
import { CartValidationEnum } from '../../../../features/agora-pages/demo/enums/cart-validation.enum';
import { DeliveryTypeObject, GetCartAvailablePaymentTypesResponse_PaymentOption, PaymentTypeObject } from '@victoria-company/agora-client';
import { Resources1 } from '../../../../state/models/resources';
import { UserStore } from '../../../../stores/user.store';
import { ContextStore } from '../../../../stores/context.store';
import { CartStore } from '../../../../stores/cart.store';
import { CheckoutStore } from '../../../../stores/chechout.store';

@Component({
  selector: 'app-cart-recap-info',
  templateUrl: './cart-recap-info.component.html',
  styleUrls: ['./cart-recap-info.component.scss'],
})
export class CartRecapInfoComponent {
  protected readonly deliveryOptions = DeliveryTypeObject;

  private contextStore = inject(ContextStore);
  private userStore = inject(UserStore);
  private checkoutStore = inject(CheckoutStore);
  public cartStore = inject(CartStore);

  @Input() resources: Resources1;
  @Output() requestOpenDeliveryInfoModal = new EventEmitter();
  @Output() requestCartDelegateApproval = new EventEmitter();
  @Output() requestRedirectUserToCatalog = new EventEmitter();
  paymentMethods: GetCartAvailablePaymentTypesResponse_PaymentOption;
  locale: string;
  operationPending = false;

  //
  isCartValidationWaiting = false;
  isClientValidationDone = false;

  isCartValid = false;
  isDemoCart = false;

  demoCode: string;
  isCartSent = false;
  orderActionEnum = OrderActionEnum;
  cartValidationEnum = CartValidationEnum;

  constructor(
    public translation: TranslateService,
    public translate: TranslationService,
    public cookie: CookieService,
    public storage: StorageService,
    private router: Router
  ) {
    effect(() => {
      if (this.cartStore?.isLoaded() && this.cartStore.paymentsOptions()) this.paymentMethods = this.cartStore.paymentsOptions()?.paymentOptions.find(p => p.paymentType == PaymentTypeObject.Digital);
    });
  }

  requestApproval() {
    this.requestCartDelegateApproval.emit();
  }

  async validateOrder() {
    await this.checkAuthenticationAndRedirectToCheckout();
  }

  private async checkAuthenticationAndRedirectToCheckout() {
    if (this.userStore.isAuthenticated()) {
      await this.checkoutStore.reset();
      await this.router.navigate(['/' + this.contextStore.locale() + '/public/checkout']);
    } else {
      this.storage.redirectTo = '/' + this.contextStore.locale() + '/public/checkout';
      await this.router.navigateByUrl('/' + this.contextStore.locale());
    }
  }

  public hasBonusRemaining() {
    return this.cartStore.cart()?.hostessBonus - this.cartStore.cart()?.subTotal >= 0;
  }

  public requestContinueShopping() {
    this.requestRedirectUserToCatalog.emit();
  }

  openShippingInfoModal() {
    this.requestOpenDeliveryInfoModal.emit();
  }
}
