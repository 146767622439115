import { SearchResponseFacetCountSchema, SearchResponseHit } from 'typesense/lib/Typesense/Documents';
import { ProductSearchModel } from '@victoria-company/agora-client';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { TypesenseService } from '../core/services/V2/typesense.service';
import { ContextStore } from './context.store';
import { DemoStore } from './demo.store';
import { CatalogService } from '../core/services/V2/catalog.service';

export type CatalogAvailableFilters = {
  categories: string[];
  colors: string[];
  genders: string[];
  themes: string[];
  trays: string[];
};
export type CatalogAppliedFilters = {
  categories: string[];
  colors: string[];
  genders: string[];
  themes: string[];
  priceRange: {
    min?: number;
    max?: number;
  };
  query?: string;
};

export type ProductSearchResponse = {
  hits: SearchResponseHit<ProductSearchModel>[];
  count: number;
  page: number;
  facet_counts: SearchResponseFacetCountSchema<ProductSearchModel>[];
};

export interface CatalogState {
  selectedTray: string;
  availableFilters?: CatalogAvailableFilters;
  appliedFilters: CatalogAppliedFilters;
  productsPerPage: number;
  searchResult?: ProductSearchResponse;
  hasMoreProductsToLoad: boolean;
  filterPanelOpened: boolean;
  productDetails: ProductSearchModel;
}

export const initialState: CatalogState = {
  selectedTray: '*',
  productsPerPage: 25,
  hasMoreProductsToLoad: false,
  filterPanelOpened: false,
  availableFilters: null,
  searchResult: null,
  appliedFilters: {
    categories: [],
    colors: [],
    genders: [],
    themes: [],
    priceRange: {},
  },
  productDetails: null,
};

export const CatalogStore = signalStore(
  { providedIn: 'root' },
  withDevtools('catalog'),
  withState(initialState),
  withComputed(store => ({
    appliedFiltersLength: computed(
      () => store.appliedFilters().categories.length + store.appliedFilters().colors.length + (store.appliedFilters().priceRange.max ? 1 : 0) + (store.appliedFilters().priceRange.min ? 1 : 0)
    ),
  })),
  withMethods((store, catalogService = inject(CatalogService), contextStore = inject(ContextStore), typeSenseService = inject(TypesenseService)) => ({
    async searchProductByIdOrReference(idOrReference: string): Promise<void> {
      if (store.productDetails() != null && (store.productDetails().id == idOrReference || store.productDetails().reference == idOrReference)) return;

      patchState(store, () => ({
        productDetails: null,
      }));

      const result = await catalogService.getProductByIdOrReference(contextStore.contextId(), contextStore.locale(), idOrReference);
      if (!result) return;

      patchState(store, () => ({
        productDetails: result,
      }));
    },
    async searchProducts(): Promise<void> {
      const result = await typeSenseService.searchProducts(
        contextStore.contextId(),
        contextStore.locale(),
        1,
        store.productsPerPage(),
        store.selectedTray() == '*' ? undefined : store.selectedTray(),
        store.appliedFilters()
      );

      patchState(store, () => ({
        hasMoreProductsToLoad: result.page * store.productsPerPage() < result.count,
        searchResult: {
          hits: result.hits,
          count: result.count,
          page: result.page,
          facet_counts: result.facet_counts,
        },
      }));
    },
  })),
  withMethods((store, demoStore = inject(DemoStore), contextStore = inject(ContextStore), typeSenseService = inject(TypesenseService)) => ({
    async retrieveTrays(): Promise<void> {
      const trays = await typeSenseService.retrieveTrays(contextStore.contextId(), contextStore.locale(), demoStore.demo().displayTraysFromKitTypes, store.appliedFilters());
      patchState(store, () => ({
        availableFilters: {
          ...store.availableFilters(),
          trays: ['*', ...trays],
        },
      }));

      if (store.selectedTray() != '*' && !trays.includes(store.selectedTray()))
        patchState(store, () => ({
          selectedTray: '*',
        }));
    },
  })),
  withMethods((store, demoStore = inject(DemoStore), contextStore = inject(ContextStore), typeSenseService = inject(TypesenseService)) => ({
    async init(): Promise<void> {
      const filters = await typeSenseService.retrieveFilters(contextStore.contextId(), contextStore.locale(), demoStore.demo().displayTraysFromKitTypes);

      patchState(store, () => ({
        availableFilters: {
          ...store.availableFilters(),
          categories: filters.categories,
          colors: filters.colors,
          genders: filters.genders,
          themes: filters.themes,
        },
      }));

      await store.retrieveTrays();
      await store.searchProducts();
    },
    async loadMoreProducts(): Promise<void> {
      if (store.hasMoreProductsToLoad()) {
        const response = await typeSenseService.searchProducts(
          contextStore.contextId(),
          contextStore.locale(),
          store.searchResult().page + 1,
          store.productsPerPage(),
          store.selectedTray() == '*' ? undefined : store.selectedTray(),
          store.appliedFilters()
        );
        patchState(store, () => ({
          hasMoreProductsToLoad: response.page * store.productsPerPage() < response.count,
          searchResult: {
            hits: [...store.searchResult().hits, ...response.hits],
            count: response.count,
            page: response.page,
            facet_counts: response.facet_counts,
          },
        }));
      }
    },
    async selectNextTray(): Promise<void> {
      patchState(store, () => ({
        selectedTray:
          store.availableFilters()?.trays?.indexOf(store.selectedTray()) + 1 == store.availableFilters()?.trays?.length
            ? store.selectedTray()
            : store.availableFilters()?.trays[store.availableFilters()?.trays?.indexOf(store.selectedTray()) + 1],
      }));

      await store.retrieveTrays();
      await store.searchProducts();
    },
    async selectPreviousTray(): Promise<void> {
      patchState(store, () => ({
        selectedTray:
          store.availableFilters()?.trays?.indexOf(store.selectedTray()) == 0
            ? store.selectedTray()
            : store.availableFilters()?.trays[store.availableFilters()?.trays?.indexOf(store.selectedTray()) - 1],
      }));

      await store.searchProducts();
    },
    async selectTray(tray: string): Promise<void> {
      patchState(store, () => ({
        selectedTray: tray,
      }));

      await store.searchProducts();
    },
    async toggleCategoryFilter(category: string): Promise<void> {
      patchState(store, () => ({
        appliedFilters: {
          ...store.appliedFilters(),
          categories: store.appliedFilters()?.categories.includes(category) ? store.appliedFilters()?.categories.filter(c => c != category) : [...store.appliedFilters().categories, category],
        },
      }));
      await store.retrieveTrays();
      await store.searchProducts();
    },
    async toggleColorFilter(color: string): Promise<void> {
      patchState(store, () => ({
        appliedFilters: {
          ...store.appliedFilters(),
          colors: store.appliedFilters().colors.includes(color) ? store.appliedFilters().colors.filter(c => c != color) : [...store.appliedFilters().colors, color],
        },
      }));
      await store.retrieveTrays();
      await store.searchProducts();
    },
    async setPriceRangeFilter(min: number, max: number): Promise<void> {
      patchState(store, () => ({
        appliedFilters: {
          ...store.appliedFilters(),
          priceRange: {
            min,
            max,
          },
        },
      }));
      await store.retrieveTrays();
      await store.searchProducts();
    },
    async setQueryFilter(query: string): Promise<void> {
      patchState(store, () => ({
        appliedFilters: {
          ...store.appliedFilters(),
          query,
        },
      }));

      await store.retrieveTrays();
      await store.searchProducts();
    },
    async resetAppliedFilters(): Promise<void> {
      patchState(store, () => ({ appliedFilters: initialState.appliedFilters }));
      await store.retrieveTrays();
      await store.searchProducts();
    },
  }))
);
