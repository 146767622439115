import { Component, inject, OnInit } from '@angular/core';
import { CardType } from '../../../../core/enums/card-type.enum';
import { UserStore } from '../../../../stores/user.store';
import { ContextStore } from '../../../../stores/context.store';
import { ErrorResponse } from '../../../../shared/components/flow-error/flow-error.component';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomeComponent implements OnInit {
  userStore = inject(UserStore);
  contextStore = inject(ContextStore);
  cardType = CardType;

  globalError:ErrorResponse



  ngOnInit() {
    if(this.userStore.isAuthenticated && !this.userStore.userCode()) this.userStore.loadUserCode().catch(err => (this.globalError = err))
  }
}
