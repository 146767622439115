import { DemoRole, DemoRoleObject, DemoStatusObject } from '@victoria-company/agora-client';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { AllowedDemoRequestStatus, ListingDemoService } from '../core/services/V2/listingDemo.service';
import { inject } from '@angular/core';
import { CartService } from '../core/services/V2/cart.service';
import { GetDemosForUserResponse_DemoResponseWithIsHostess } from '@victoria-company/agora-client/dist/models';
import { DemoService } from '../core/services/V2/demo.service';

export type ListingDemoItem = GetDemosForUserResponse_DemoResponseWithIsHostess & { total: number; canNavigateTo: boolean; isActiveDemo: boolean; isFutureDemo: boolean; isPastDemo: boolean };
export interface ListingDemoState {
  isLoaded: boolean;
  isLoading: boolean;
  headers: {
    pastCount: number;
    currentCount: number;
    futureCount: number;
  };
  displayMode: DemoRole;
  items: ListingDemoItem[];
  itemsCount: number;
  index: number;
  take: number;
  hasNext: boolean;
}

export const initialState: ListingDemoState = {
  isLoaded: false,
  isLoading: true,
  displayMode: undefined,
  items: [],
  itemsCount: 0,
  headers: {
    pastCount: 0,
    currentCount: 0,
    futureCount: 0,
  },
  index: 0,
  take: 20,
  hasNext: false,
};

export const ListingDemoStore = signalStore(
  { providedIn: 'root' },
  withDevtools('listing demo'),
  withState(initialState),
  withMethods((store, listingDemoService = inject(ListingDemoService)) => ({
    async loadDemoListingSummary(): Promise<void> {
      const today = new Date();
      const fromDate = new Date(today.setDate(today.getDate() - 8));
      const summary = await listingDemoService.getListingDemoSummary(fromDate);
      patchState(store, () => ({
        headers: {
          pastCount: summary.past,
          currentCount: summary.present,
          futureCount: summary.future,
        },
      }));
    },
    async loadDelegateListingDemo(role: DemoRole, index: number, take: number, demoStatus: AllowedDemoRequestStatus): Promise<void> {
      const today = new Date();

      patchState(store, () => ({ displayMode: role }));

      //Only fetch future demos from today -8 days
      const fromDate = demoStatus == 'Future' ? new Date(today.setDate(today.getDate() - 8)) : null;
      const orderBy = demoStatus == 'Past' ? 'Descending' : 'Ascending';
      const listing = await listingDemoService.getListingDemo(role, index, take, demoStatus, fromDate, orderBy);
      const items = index != 0 ? [...store.items()] : [];
      const demos = listing.demos as ListingDemoItem[];

      demos.forEach(d => {
        (d.total = 0),
          (d.canNavigateTo = true),
          (d.isActiveDemo = d.demo?.status == DemoStatusObject.Opened),
          (d.isFutureDemo = d.demo?.status == DemoStatusObject.PreSale || d.demo?.status == DemoStatusObject.Created),
          (d.isPastDemo = d.demo?.status == DemoStatusObject.Closed || d.demo?.status == DemoStatusObject.PostSale);
      });

      patchState(store, () => ({
        items: [...items, ...demos],
        isLoaded: true,
        isLoading: false,
        displayMode: DemoRoleObject.Delegate,
        itemsCount: listing.itemCount,
        index: store.index() + 1,
        hasNext: store.items().length + listing.demos.length < listing.itemCount,
      }));
    },
    async loadAttendeeListingDemo(role: DemoRole, index: number, take: number): Promise<void> {
      patchState(store, () => ({ displayMode: role }));

      const listing = await listingDemoService.getListingDemo(role, index, take, 'All');
      const items = index != 0 ? [...store.items()] : [];
      const demos = listing.demos as ListingDemoItem[];
      demos.forEach(d => {
        (d.total = 0),
          (d.canNavigateTo = d.demo?.status == DemoStatusObject.Opened || d.demo?.status == DemoStatusObject.PostSale || (d?.isHostess && d.demo?.status == DemoStatusObject.PreSale)),
          (d.isActiveDemo = d.demo?.status == DemoStatusObject.Opened),
          (d.isFutureDemo = d.demo?.status == DemoStatusObject.PreSale || d.demo?.status == DemoStatusObject.Created),
          (d.isPastDemo = d.demo?.status == DemoStatusObject.Closed || d.demo?.status == DemoStatusObject.PostSale);
      });

      patchState(store, () => ({
        items: [...items, ...demos],
        isLoaded: true,
        isLoading: false,
        displayMode: DemoRoleObject.Attendee,
        itemsCount: listing.itemCount,
        index: store.index() + 1,
        hasNext: store.items().length + listing.demos.length < listing.itemCount,
      }));
    },
    async setListingDemoLoaded() {
      patchState(store, {
        isLoaded: true,
        isLoading: false,
      });
    },
  })),
  withMethods(store => ({
    async switchDisplayMode(displayMode: DemoRole): Promise<void> {
      patchState(store, () => ({
        items: [],
        itemsCount: 0,
        index: 0,
        hasNext: false,
        isLoading: true,
        displayMode,
      }));

      if (displayMode == 'Delegate') await store.loadDelegateListingDemo(displayMode, 0, 20, 'Present');
      else await store.loadAttendeeListingDemo(displayMode, 0, 20);
    },

    async switchDemoStatus(demoStatus: AllowedDemoRequestStatus): Promise<void> {
      patchState(store, () => ({
        items: [],
        itemsCount: 0,
        index: 0,
        hasNext: false,
        isLoading: true,
        displayMode: DemoRoleObject.Delegate,
      }));

      await store.loadDelegateListingDemo('Delegate', 0, 20, demoStatus);
    },
  })),
  withMethods((store, cartService = inject(CartService), demoService = inject(DemoService)) => ({
    async affectUserCartToDemo(cartId: string, demoCode: string) {
      await cartService.affectCartToDemo(cartId, demoCode);
    },
    async createFakeDemo() {
      await demoService.createDummy();
    },
  }))
);
