<app-page-title [mainTitle]="'STAFF.MANAGER.TITLE' | translate" [subTitle]="'STAFF.MANAGER.SUBTITLE' | translate"> </app-page-title>

<div class="manager-container">
  <div class="cta-container">
    <button *ngIf="userStore.isDocumentsAdmin()" class="secondary" (click)="goToManager(DocumentCategoryObject.CommercialAction)">
      <label>{{ 'COMMERCIAL.ACTION.TITLE' | translate }}</label>
    </button>
    <button *ngIf="userStore.isGuideAdmin()" class="secondary" (click)="goToManager(DocumentCategoryObject.HostessGuide)">
      <label>{{ 'HOSTESS.GUIDE.TITLE' | translate }}</label>
    </button>
  </div>
</div>
