<div class="optin-dip-box-container" *ngIf="cart && cart.preContractualInformationDocument?.required">
  <h1 class="canela">{{ 'DEMO.TUNNEL.DIP.VALIDATION.TITLE' | translate }}</h1>
  <div class="flex row ai-start">
    <label class="flex row checkbox path checkbox-optin" (click)="signDIP($event)">
      <input type="checkbox" [checked]="cart.preContractualInformationDocument?.signed" [disabled]="cart.preContractualInformationDocument?.signed" />
      <svg viewBox="0 0 28 28">
        <use xlink:href="#custom-checkbox"></use>
      </svg>
    </label>
    <p [innerHTML]="'DEMO.TUNNEL.DIP.VALIDATION.OPTIN' | translate"></p>
  </div>
</div>
