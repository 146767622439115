import { Component, effect, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextStore } from '../../../../stores/context.store';
import { RGPDService } from '../../../../core/services/V2/rgpd.service';
import { GdprOptInSourceObject, GdprResponse } from '@victoria-company/agora-client';
import { ErrorResponse } from '../../../../shared/components/flow-error/flow-error.component';
import { ResourcesStore } from '../../../../stores/resources.store';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss'],
})
export class CommunicationsComponent {
  readonly resourcesStore = inject(ResourcesStore);
  readonly contextStore = inject(ContextStore);
  readonly rgpdService = inject(RGPDService);
  readonly activeRoute = inject(ActivatedRoute);
  readonly router = inject(Router);

  public RGPDData: GdprResponse = {
    isOptIn: false,
    delegateConnections: [],
    isSubscribedCommercial: false,
    isSubscribedNewsletter: false,
  };
  public noRGPDData: boolean = false;
  public globalError: ErrorResponse;

  private RGPDId: string;
  private RGPDLoading = false;

  constructor() {
    effect(async () => {
      if (this.resourcesStore.isLoaded()) {
        this.RGPDId = this.activeRoute.snapshot.params.gdprId;
        if (!this.RGPDId) await this.router.navigateByUrl('/');
        else if (!this.RGPDLoading) {
          this.RGPDLoading = true;
          try {
            this.globalError = undefined;
            this.RGPDData = await this.rgpdService.getRGPDDataForVisitor(this.RGPDId);
            this.noRGPDData = false;
          } catch (err) {
            this.globalError = err;
            this.noRGPDData = true;
          } finally {
            this.RGPDLoading = false;
          }
        }
      }
    });
  }

  async updateOptIn(value: boolean) {
    try {
      if (value) await this.rgpdService.setOptIn(GdprOptInSourceObject.Web);
      else await this.rgpdService.unsetOptIn();
    } catch (err) {
      this.globalError = err;
    }
  }

  async updateDelegateRGPD(value: boolean, delegateId: string) {
    try {
      this.globalError = undefined;
      this.noRGPDData = false;
      if (value) await this.rgpdService.setVisitorDelegateConnection(this.RGPDId, delegateId);
      else await this.rgpdService.unsetVisitorDelegateConnection(this.RGPDId, delegateId);
    } catch (err) {
      this.noRGPDData = true;
      this.globalError = err;
    }
  }
}
