export enum DomainException {
  //GENERIC
  Generic = 'Generic',
  //GLOBAL
  NotFound = 'NotFound',
  ServerError = 'ServerError',
  BadRequest = 'BadRequest',
  Unauthorized = 'Unauthorized',
  //CLUB
  CodeAlreadyEncodedBySomeoneElseDomainException = 'CodeAlreadyEncodedBySomeoneElseDomainException',
  CodeAlreadyEncodedByUserItSelfDomainException = 'CodeAlreadyEncodedByUserItSelfDomainException',
  CodeNotFoundDomainException = 'CodeNotFoundDomainException',
  InvalidVoucherDataDomainException = 'InvalidVoucherDataDomainException',
  ClubActionNotFoundDomainException = 'ClubActionNotFoundDomainException',
  NoActiveStatusesFoundDomainException = 'NoActiveStatusesFoundDomainException',
  //VOUCHERS
  CannotFindVoucherException = 'CannotFindVoucherException',
  CannotLockAlreadyLockedVoucherException = 'CannotLockAlreadyLockedVoucherException',
  CannotModifyAlreadyUsedVoucherException = 'CannotModifyAlreadyUsedVoucherException',
  CannotRevokeAlreadyUsedOrLockedVoucherException = 'CannotRevokeAlreadyUsedOrLockedVoucherException',
  CannotUseNotLockedVoucherException = 'CannotUseNotLockedVoucherException',
  CannotUseOrLockExpiredVoucherException = 'CannotUseOrLockExpiredVoucherException',
  CannotUseOrLockRevokedVoucherException = 'CannotUseOrLockRevokedVoucherException',
  VoucherAlreadyExistException = 'VoucherAlreadyExistException',
  VoucherHasNoOriginalUserException = 'VoucherHasNoOriginalUserException',
  //CART
  ApprovalRequiredBeforeCheckoutException = 'ApprovalRequiredBeforeCheckoutException',
  CannotAssignCartToClosedDemoDomainException = 'CannotAssignCartToClosedDemoDomainException',
  CannotAssignInactiveDelegateDomainException = 'CannotAssignInactiveDelegateDomainException',
  CannotChangeDelegateOnCartInADemoDomainException = 'CannotChangeDelegateOnCartInADemoDomainException',
  CannotConfirmCartWithOpenDelegatePaymentDomainException = 'CannotConfirmCartWithOpenDelegatePaymentDomainException',
  CannotHaveWalletAmountOnAnonymousCartDomainException = 'CannotHaveWalletAmountOnAnonymousCartDomainException',
  CannotMergeConfirmedCartDomainException = 'CannotMergeConfirmedCartDomainException',
  CannotModifyCartNotActiveException = 'CannotModifyCartNotActiveException',
  CannotModifyCartInPendingPaymentException = 'CannotModifyCartInPendingPaymentException',
  CannotUnsetHostessCartNotActiveDomainException = 'CannotUnsetHostessCartNotActiveDomainException',
  CartAlreadyAssignedToADifferentUserException = 'CartAlreadyAssignedToADifferentUserException',
  CartIsEmptyException = 'CartIsEmptyException',
  CartIsNotPaidException = 'CartIsNotPaidException',
  CartIsNotPartOfADemoException = 'CartIsNotPartOfADemoException',
  CartItemIdNotFoundInCartException = 'CartItemIdNotFoundInCartException',
  CartNotAssignedDomainException = 'CartNotAssignedDomainException',
  CartNotFoundException = 'CartNotFoundException',
  CartTotalIsZeroException = 'CartTotalIsZeroException',
  DeliveryAddressRequiredBeforeCheckoutException = 'DeliveryAddressRequiredBeforeCheckoutException',
  DeliveryAddressRequiredWhenDeliveryTypeIsToCustomerException = 'DeliveryAddressRequiredWhenDeliveryTypeIsToCustomerException',
  DeliveryTypeNotAssignedDomainException = 'DeliveryTypeNotAssignedDomainException',
  InvalidCartStatusTransitionException = 'InvalidCartStatusTransitionException',
  InvalidLinkModificationDomainException = 'InvalidLinkModificationDomainException',
  PreContractualInformationDocumentSignedRequiredBeforeCheckoutException = 'PreContractualInformationDocumentSignedRequiredBeforeCheckoutException',
  UpdateItemQuantityMustBePositiveException = 'UpdateItemQuantityMustBePositiveException',
  UserAlreadyHasAnActiveCartException = 'UserAlreadyHasAnActiveCartException',
  VoucherIsNotUsableException = 'VoucherIsNotUsableException',
  //CLUB REFFERAL
  ClubReferralNotFoundDomainException = 'ClubReferralNotFoundDomainException',
  //CATALOG
  //WALLET
  CouldNotFindWalletReservationDomainException = 'CouldNotFindWalletReservationDomainException',
  InsufficientWalletAmountDomainException = 'InsufficientWalletAmountDomainException',
  WalletAmountMustBePositiveDomainException = 'WalletAmountMustBePositiveDomainException',
  WalletNotFoundDomainException = 'WalletNotFoundDomainException',
  WalletOperationAmountMustBePositiveDomainException = 'WalletOperationAmountMustBePositiveDomainException',
  WalletReservationAmountMustBePositiveDomainException = 'WalletReservationAmountMustBePositiveDomainException',
  //DEMO
  DemoNotFoundDomainException = 'DemoNotFoundDomainException',
  //STOCK
  NotEnoughStockDomainException = 'NotEnoughStockDomainException',
  CannotAddEGiftsToHostessCartException = 'CannotAddEGiftsToHostessCartException'
}
