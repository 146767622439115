import { Injectable, inject } from '@angular/core';
import { GetTypesenseSettingsResponse, KitType, ProductSearchModel } from '@victoria-company/agora-client';
import { ApiService } from './api.service';
import { SearchClient } from 'typesense';
import { SearchResponseFacetCountSchema, SearchResponseHit } from 'typesense/lib/Typesense/Documents';
import { CatalogAppliedFilters } from '../../../stores/catalog.store';

@Injectable({
  providedIn: 'root',
})
export class TypesenseService {
  private api = inject(ApiService);
  private client: SearchClient;

  private async getClientInstance(): Promise<SearchClient> {
    if (this.client) return this.client;

    const typeSenseSettings: GetTypesenseSettingsResponse = await this.api.getTypeSenseSettings();

    this.client = new SearchClient({
      nodes: typeSenseSettings.nodes.map(n => ({
        host: n.host!,
        port: n.port!,
        protocol: n.protocol!,
      })),
      nearestNode: typeSenseSettings.nearestNode
        ? {
            host: typeSenseSettings.nearestNode.host!,
            port: typeSenseSettings.nearestNode.port!,
            protocol: typeSenseSettings.nearestNode.protocol!,
          }
        : undefined,
      apiKey: typeSenseSettings.apiKey!,
      numRetries: 3,
      connectionTimeoutSeconds: 10,
      retryIntervalSeconds: 0.1,
      healthcheckIntervalSeconds: 2,
      logLevel: 'debug',
    });

    return this.client;
  }

  public async retrieveFilters(contextId: number, language: string, kitTypes?: KitType[]) {
    const client = await this.getClientInstance();
    const response = await client
      .collections<ProductSearchModel>('products_' + contextId + '_' + language)
      .documents()
      .search(
        {
          q: '*',
          query_by: 'name,description,variants.id,variants.sku,variants.size,assets.reference,trays.name,themes.name,category.name,gender.name,color.name',
          facet_by: 'category.name,color.name,gender.name,themes.name',
          exhaustive_search: true,
          max_facet_values: 100,
          filter_by: kitTypes ? `trays.type:=[${kitTypes.join(',')}]` : undefined,
        },
        {}
      );

    return {
      //The use of as keyof ProductSearchModel is due the library not handling subfield properly
      categories: response.facet_counts.find(f => f.field_name == ('category.name' as keyof ProductSearchModel))?.counts.map(f => f.value) ?? [],
      colors: response.facet_counts.find(f => f.field_name == ('color.name' as keyof ProductSearchModel))?.counts.map(f => f.value) ?? [],
      genders: response.facet_counts.find(f => f.field_name == ('gender.name' as keyof ProductSearchModel))?.counts.map(f => f.value) ?? [],
      themes: response.facet_counts.find(f => f.field_name == ('themes.name' as keyof ProductSearchModel))?.counts.map(f => f.value) ?? [],
    };
  }

  public async retrieveTrays(contextId: number, language: string, kitTypes?: KitType[], appliedFilters?: CatalogAppliedFilters) {
    const kitTypesFilter = kitTypes ? `trays.type:=[${kitTypes.join(',')}]` : undefined;
    const filter = [kitTypesFilter, generateFilterStringForAppliedFilters(appliedFilters)].filter(f => f).join(' && ');

    const client = await this.getClientInstance();
    const response = await client
      .collections<ProductSearchModel>('products_' + contextId + '_' + language)
      .documents()
      .search(
        {
          q: appliedFilters.query ?? '*',
          query_by: 'name,description,variants.id,variants.sku,variants.size,assets.reference,trays.name,themes.name,category.name,gender.name,color.name',
          facet_by: 'trays.name',
          exhaustive_search: true,
          max_facet_values: 100,
          filter_by: filter,
        },
        {}
      );

    return (
      response.facet_counts
        .find(f => f.field_name == ('trays.name' as keyof ProductSearchModel))
        ?.counts.map(f => f.value)
        .filter(filterTraysForKitTypes(kitTypes))
        .sort((a, b) => {
          const num = parseInt(a.slice(1), 10);
          const nextNum = parseInt(b.slice(1), 10);
          return num - nextNum;
        }) ?? []
    );
  }

  public async searchProducts(contextId: number, language: string, page: number, perPage: number, tray?: string, appliedFilters?: CatalogAppliedFilters) {
    const trayFilter = tray ? `trays.name:=${tray}]` : undefined;
    const filter = [trayFilter, generateFilterStringForAppliedFilters(appliedFilters)].filter(f => f).join(' && ');

    const client = await this.getClientInstance();
    const response = await client
      .collections<ProductSearchModel>('products_' + contextId + '_' + language)
      .documents()
      .search(
        {
          q: appliedFilters?.query ?? '*',
          query_by: 'name,description,variants.id,variants.sku,variants.size,assets.reference,trays.name,themes.name,category.name,gender.name,color.name',
          facet_by: 'category.name,color.name,gender.name,themes.name,trays.name',
          exhaustive_search: true,
          max_facet_values: 50,
          filter_by: filter,
          page,
          per_page: perPage,
        },
        {}
      );
    return {
      hits: response.hits as SearchResponseHit<ProductSearchModel>[],
      count: response.found,
      page: response.page,
      facet_counts: response.facet_counts as SearchResponseFacetCountSchema<ProductSearchModel>[],
    };
  }
}

function generateFilterStringForAppliedFilters(appliedFilters?: CatalogAppliedFilters): string | undefined {
  const categoriesFilter = appliedFilters?.categories.length ?? 0 > 0 ? `category.name:=[${appliedFilters.categories.join(',')}]` : undefined;
  const colorsFilter = appliedFilters?.colors.length ?? 0 > 0 ? `color.name:=[${appliedFilters.colors.join(',')}]` : undefined;
  const gendersFilter = appliedFilters?.genders.length ?? 0 > 0 ? `gender.name:=[${appliedFilters.genders.join(',')}]` : undefined;
  const themesFilter = appliedFilters?.themes.length ?? 0 > 0 ? `themes.name:=[${appliedFilters.categories.join(',')}]` : undefined;
  const priceFilter = appliedFilters?.priceRange.min
    ? appliedFilters?.priceRange.max
      ? `lowestVariantCurrentPrice:[${appliedFilters?.priceRange.min}..${appliedFilters?.priceRange.max}]`
      : `lowestVariantCurrentPrice:>${appliedFilters?.priceRange.min}`
    : appliedFilters?.priceRange.max
      ? `lowestVariantCurrentPrice:<${appliedFilters?.priceRange.max}`
      : undefined;

  const filter = [categoriesFilter, colorsFilter, gendersFilter, themesFilter, priceFilter].filter(f => f).join(' && ');
  return filter != '' ? filter : undefined;
}

//This is not ideal, we should review to how to store the tray information on the product in typesense, but we keep it like that for now
function filterTraysForKitTypes(kitTypes: KitType[]): (value: string) => boolean {
  const allowedToStartsWith = kitTypes.map(kt => {
    switch (kt) {
      case 'Full':
        return 'F';
      case 'A':
        return 'A';
      case 'B':
        return 'B';
      case 'C':
        return 'C';
      case 'X':
        return 'X';
      case 'Y':
        return 'Y';
      case 'None':
        return 'NONE';
    }
  });
  return value => allowedToStartsWith.some(s => value.startsWith(s));
}
