import { Component, Input } from '@angular/core';
import { CartSummaryResponse } from '@victoria-company/agora-client';

@Component({
  selector: 'app-finalized-carts-list-datas',
  templateUrl: './finalized-carts-list-datas.component.html',
  styleUrls: ['./finalized-carts-list-datas.component.scss'],
})
export class FinalizedCartsListDatasComponent {
  @Input() carts: CartSummaryResponse[];
}
