import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from 'src/app/core/services/error.service';
import { CartSummaryResponse, OrderResponse } from '@victoria-company/agora-client';
import { ContextStore } from '../../../../../stores/context.store';

@Component({
  selector: 'app-waiting-orders-list-datas',
  templateUrl: './waiting-orders-list-datas.component.html',
  styleUrls: ['./waiting-orders-list-datas.component.scss'],
})
export class WaitingOrdersListDatasComponent {
  @Input() orders: OrderResponse[];
  @Input() carts: CartSummaryResponse[];
  readonly contextStore = inject(ContextStore);

  constructor(
    private router: Router,
    public error: ErrorService
  ) {}

  async navigate(id: number) {
    await this.router.navigate(['order', id], { replaceUrl: true });
  }
}
