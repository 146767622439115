<div class="cart-item-wrapper {{ classCondition }}" *ngIf="product">
  <div class="cart-item" [class.border-bottom]="bottomBorder">
    <div class="cart-item-container flex ai-start">
      <div class="cart-item-thumb">
        <figure class="image-cover magnify" (click)="zoomInImage()">
          <img [src]="dataService.getProductImagesPathForThumbs(product.imageThumbnail)" alt="photo de l'article" />
        </figure>
      </div>
      <div class="cart-item-content">
        <div>
          <div class="cart-item-head">
            <div class="flex row cart-item-price-label">
              <div class="cart-item-name flex column m-row gap-5 ai-baseline" (click)="goToProduct(product)">
                {{ product.name }}
                <span class="item_reference">({{ getProductVariantReference(product, cartItem.productVariantId) }})</span>
              </div>
              <div class="cart-item-price">
                <span [class.promo-price]="getLowestVariantNormalPrice(product) && getLowestVariantNormalPrice(product) != product.lowestVariantCurrentPrice" >{{ cartItem.price | agoraCurrency }}</span>
                <span class="old-price" *ngIf="getLowestVariantNormalPrice(product) && getLowestVariantNormalPrice(product) != product.lowestVariantCurrentPrice"> {{ getLowestVariantNormalPrice(product) | agoraCurrency}}</span>
              </div>
            </div>
            <div class="cart-item-remove" (click)="removeFromCart()" *ngIf="!isReadOnly && (!cartItem.isLsc || (cartItem.isLsc && isDemoDelegate()))">
              <svg viewBox="0 0 32 32" class="icon">
                <use xlink:href="#icon-trash"></use>
              </svg>
            </div>
          </div>
          <div class="cart-item-controls flex row gap-20" *ngIf="!isReadOnly && product.variants?.length > 1">
            <div class="cart-item-control">
              <div class="control-label" *ngIf="product.variants?.length > 1">{{ resourcesStore.i18n().resources.shoppingBag.sizesLabels }}</div>
              <div class="control-content">
                <select *ngIf="product.variants?.length > 1" class="control-select limited-size" (change)="updateItemSize($event.target)" [disabled]="isReadOnly">
                  <!-- TODO : Check the disable field and stock management -->
                  <option
                    *ngFor="let variant of product.variants"
                    [value]="variant.id"
                    [selected]="variant.id == cartItem.productVariantId"
                    [disabled]="!getVariantAvailability(variant) || (cartItem.isLsc && !isDemoDelegate())"
                    [class.barre]="!getVariantAvailability(variant)">
                    {{ variant.size }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <p *ngIf="cartItem.isLsc && isDemoDelegate()" class="lsc-box-warning">{{ 'ARTICLE.LSC.DELEGATE' | translate }}</p>
          <p *ngIf="cartItem.isLsc && !isDemoDelegate()" class="lsc-box-warning">{{ 'ARTICLE.LSC.CLIENT' | translate }}</p>

          <div *ngIf="!objIsEmpty(product.linkConfiguration)">
            <app-item-links-management
              [min]="product.linkConfiguration.removable"
              [max]="product.linkConfiguration.additional"
              [value]="cartItem.linkModification ?? 0"
              [isReadonly]="isReadOnly"
              (requestChangeItemLinks)="updateCartItemLinks($event)" />
          </div>

          <div *ngIf="isReadOnly" class="flex row j-space-between read-only-datas">
            <p class="p-s bold" *ngIf="selectedVariant().size && product.variants?.length > 1">{{ resourcesStore.i18n().resources.shoppingBag.sizesLabels }}: {{ selectedVariant().size ?? '/' }}</p>
          </div>
        </div>
        <div class="margin-top-15">
          <div class="add-to-wishlist flex ai-center cursor" *ngIf="isProductAvailable() && !isReadOnly" (click)="addItemToCart()">
            <button class="heart-button flex center-all">
              <svg viewBox="0 0 32 32" class="icon">
                <use xlink:href="#icon-plus"></use>
              </svg>
            </button>
            <p *ngIf="product.variants?.length > 1">{{ resourcesStore.i18n().resources.shoppingBag.addToBagLabel }}</p>
            <p *ngIf="product.variants?.length == 1">{{ 'GLOBAL.ADDTOCART' | translate }}</p>
          </div>
          <div class="add-to-wishlist flex ai-center cursor" *ngIf="!isReadOnly" (click)="addRemoveItemFromWishlist()">
            <button class="heart-button flex center-all">
              <svg viewBox="0 0 32 32" class="icon" [class.heart-animated]="isInWishlist">
                <use [attr.xlink:href]="'#' + (isInWishlist ? 'icon-heart-filled' : 'icon-wishlist')"></use>
              </svg>
            </button>
            <p>{{ isInWishlist ? ('CART.WISHLIST.ITEM' | translate) : ('CART.WISHLIST.ADD' | translate) }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--    &lt;!&ndash; TODO : CHECK THIS FALSE IN NGIF &ndash;&gt;-->
    <!--    <div *ngIf="false" class="d-width-fit right move-to-cart flex row ai-center" (click)="addItemToCart()">-->
    <!--      <svg viewBox="0 0 32 32" class="icon icon-block">-->
    <!--        <use xlink:href="#icon-bag"></use>-->
    <!--      </svg>-->
    <!--      <label>{{ 'DEMO.ORDER.MOVEWISHLISTTOCART' | translate }}</label>-->
    <!--    </div>-->
  </div>
</div>
