import { Component, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { filterURL, getProfilePictureOrDefault, getVictoriaUrl } from 'src/app/core/utils/filter.utils';
import { OrderResponse } from '@victoria-company/agora-client';
import { ResourcesStore } from '../../../stores/resources.store';
import { ContextStore } from '../../../stores/context.store';
import { toDeliveryId, toOrderStatusId } from '../../../core/utils/enumToInt';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent {
  @ViewChild('orderArticleDescription', { static: true })
  protected orderArticleDescription: TemplateRef<any>;

  @Input() order: OrderResponse;
  @Input() displayBackToOrders: { title: string; link: string };

  readonly resourcesStore = inject(ResourcesStore);
  readonly contextStore = inject(ContextStore);

  //Zoom Modal
  zoomModalOpened = false;
  zoomModalImage = '';

  openZoomModal(imageUrl: string) {
    this.zoomModalImage = imageUrl;
    this.zoomModalOpened = true;
  }

  closeZoomModal() {
    this.zoomModalOpened = false;
  }

  hasOnlyGift() {
    return this.order.orderItems.filter(x => x.sku.startsWith('EGIFT')).length == this.order.orderItems.length;
  }

  public filter(url) {
    return getVictoriaUrl(this.contextStore.locale(), true) + filterURL(url);
  }

  protected readonly getProfilePictureOrDefault = getProfilePictureOrDefault;
  protected readonly toDeliveryId = toDeliveryId;
  protected readonly toOrderStatusId = toOrderStatusId;
}
