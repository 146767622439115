import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-item-links-management',
  templateUrl: './item-links-management.component.html',
  styleUrls: ['./item-links-management.component.scss'],
})
export class ItemLinksManagementComponent implements OnDestroy {
  @Input() isReadonly = true;
  @Input() min: number = 0;
  @Input() max: number = 0;
  @Input() value: number = 0;
  @Output() requestChangeItemLinks = new EventEmitter<number>();

  private subscription: Subscription;
  private linksValueSubject = new Subject<number>();
  constructor() {
    this.subscription = this.linksValueSubject.pipe(debounceTime(1000)).subscribe((finalValue: number) => {
      this.requestChangeItemLinks.emit(finalValue);
    });
  }

  modifyLinks(value: number) {
    if (value < 0 && this.value > -this.min) {
      this.value -= 1;
      this.linksValueSubject.next(this.value);
    } else if (value > 0 && this.value < this.max) {
      this.value += 1;
      this.linksValueSubject.next(this.value);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  protected readonly Math = Math;
}
