<div class="point-history-wrapper-container">
  <h2 class="table-title table-filter">{{ 'ENCODING.TABLE.TITLE' | translate }}</h2>

  <div *ngIf="loaded">
    <div class="filter no-line-height">
      <div class="d-only flex">
        <p class="filter-title flex ai-center">{{ 'ENCODING.TABLE.FILTERS' | translate }}</p>
        <div class="form-group row m-column" [formGroup]="formFilter">
          <div class="form-group flex ai-center filter-option">
            <div class="flex row checkbox path">
              <input type="checkbox" class="checkbox-input" formControlName="showPurchases" />
              <svg viewBox="0 0 28 28">
                <use xlink:href="#custom-checkbox"></use>
              </svg>
            </div>
            <p>{{ 'ENCODING.TABLE.FILTERS.BUY' | translate }}</p>
          </div>
          <div class="form-group flex ai-center filter-option">
            <div class="flex row checkbox path">
              <input type="checkbox" class="checkbox-input" formControlName="showVouchers" />
              <svg viewBox="0 0 28 28">
                <use xlink:href="#custom-checkbox"></use>
              </svg>
            </div>
            <p>{{ 'ENCODING.TABLE.FILTERS.VOUCHER' | translate }}</p>
          </div>
          <div class="form-group flex ai-center filter-option">
            <div class="flex row checkbox path">
              <input type="checkbox" class="checkbox-input" formControlName="showOthers" />
              <svg viewBox="0 0 28 28">
                <use xlink:href="#custom-checkbox"></use>
              </svg>
            </div>
            <p>{{ 'ENCODING.TABLE.FILTERS.OTHERS' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="m-only">
        <form [formGroup]="formFilter">
          <div class="filter no-line-height no-padding-bottom">
            <div class="row flex fullwidth-only j-space-between">
              <div class="filter-title flex ai-center">
                <p>{{ 'ENCODING.TABLE.FILTERS.BUY' | translate }}</p>
              </div>
              <div class="filter-input">
                <label class="switch">
                  <input type="checkbox" formControlName="showPurchases" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="filter no-line-height no-padding-bottom">
            <div class="row flex fullwidth-only j-space-between">
              <div class="filter-title flex ai-center">
                <p>{{ 'ENCODING.TABLE.FILTERS.VOUCHER' | translate }}</p>
              </div>
              <div class="filter-input">
                <label class="switch">
                  <input type="checkbox" formControlName="showVouchers" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="filter no-line-height no-padding-bottom">
            <div class="row flex fullwidth-only j-space-between">
              <div class="filter-title flex ai-center">
                <p>{{ 'ENCODING.TABLE.FILTERS.OTHERS' | translate }}</p>
              </div>
              <div class="filter-input">
                <label class="switch">
                  <input type="checkbox" formControlName="showOthers" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="table">
      <app-point-history-datas [actions]="userStore.clubActions()?.actions" (triggerCall)="getMoreDatas()"> </app-point-history-datas>
      <div class="table-loader flex j-center ai-center" *ngIf="!loadedMore">
        <div class="loader-bullet">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="more d-only no-padding-top">
      <div (click)="getMoreDatas()" *ngIf="userStore.clubActions()?.hasMore">
        <p class="uppercase">{{ 'GLOBAL.ACTION.MORE' | translate }}</p>
        <svg *ngIf="loadedMore" viewBox="0 0 32 32">
          <use xlink:href="#icon-arrow-right"></use>
        </svg>
        <div *ngIf="!loadedMore" class="loader loader-mini"></div>
      </div>
    </div>
  </div>
  <div *ngIf="!loaded" class="flex center-all loading">
    <div class="loader"></div>
  </div>
</div>
<app-flow-error *ngIf="globalError" [error]="globalError" />
