import { Component, inject, Input, OnInit } from '@angular/core';
import { GetClubInformationResponse } from '@victoria-company/agora-client';
import { getStatusIdFromClubStatus } from '../../../../../core/utils/common.utils';
import { ContextStore } from '../../../../../stores/context.store';
import { UserStore } from '../../../../../stores/user.store';
import { ErrorResponse } from '../../../flow-error/flow-error.component';

@Component({
  selector: 'app-status-data-wrapper',
  templateUrl: './status-data-wrapper.component.html',
  styleUrls: ['./status-data-wrapper.component.scss'],
})
export class StatusDataWrapperComponent implements OnInit {
  @Input() clubInformation: GetClubInformationResponse;

  readonly userStore = inject(UserStore);
  readonly contextStore = inject(ContextStore);
  globalError: ErrorResponse;

  async ngOnInit() {
    this.globalError = undefined;

    try {
      await this.userStore.getTrackableActions(this.contextStore.contextId());
    } catch (err) {
      this.globalError = err;
    }
  }

  protected readonly getStatusIdFromClubStatus = getStatusIdFromClubStatus;
}
