<div class="communications-container" *ngIf="RGPDData">
  <div class="header flex center-all column">
    <img loading="lazy" src="/assets/images/logo.svg" alt="Logo Victoria" />
    <h1>{{ 'COMMUNICATION.TITLE' | translate }}</h1>
    <div class="dark"></div>
  </div>
  <div class="body-title flex ai-center column">
    <p class="subtitle" [innerHTML]="'COMMUNICATION.CAPTION' | translate"></p>
    <div class="heading flex column ai-center">
      <h2>{{ 'COMMUNICATION.EMAIL' | translate }}</h2>
      <h3>{{ RGPDData.email ?? 'unknown email address' }}</h3>
    </div>
  </div>
  <div class="body-communications container flex column ai-start">
    <div class="communications-main">
      <h2>{{ 'PROFILE.COMMUNICATION.VICTORIA' | translate }}</h2>
      <div class="flex row">
        <label class="flex row checkbox path" *ngIf="RGPDData">
          <input type="checkbox" [(ngModel)]="RGPDData.isOptIn" (ngModelChange)="updateOptIn($event)" />
          <svg viewBox="0 0 28 28">
            <use xlink:href="#custom-checkbox"></use>
          </svg>
          {{ 'PROFILE.COMMUNICATION.VICTORIA.CONSENT' | translate }}
        </label>
      </div>

      <div class="warning flex row ai-center" *ngIf="!noRGPDData && RGPDData && !RGPDData?.isOptIn">
        <div>
          <svg viewBox="0 0 28 28">
            <use xlink:href="#icon-warning"></use>
          </svg>
        </div>
        <div [innerHTML]="'PROFILE.COMMUNICATION.NOSUBSCRIBE' | translate"></div>
      </div>
    </div>
    <div class="communications-delegate">
      <h2>{{ 'PROFILE.COMMUNICATION.DELEGATES' | translate }}</h2>
      <div *ngIf="RGPDData && RGPDData?.delegateConnections?.length">
        <p>{{ 'PROFILE.COMMUNICATION.DELEGATES.CONSENT' | translate }}</p>
        <div class="flex row" *ngFor="let rgpdInfo of RGPDData?.delegateConnections">
          <label class="flex row checkbox path text-margin-bottom">
            <input type="checkbox" [(ngModel)]="rgpdInfo.isOptIn" (ngModelChange)="updateDelegateRGPD($event, rgpdInfo.delegateId)" />
            <svg viewBox="0 0 28 28">
              <use xlink:href="#custom-checkbox"></use>
            </svg>
            {{ rgpdInfo.delegateName }}
          </label>
        </div>
      </div>
      <p *ngIf="!RGPDData || !RGPDData?.delegateConnections?.length">{{ 'PROFILE.COMMUNICATION.NODELEGATES' | translate }}</p>
    </div>
  </div>
  <div class="body-communications container flex column ai-center error-box" *ngIf="globalError">
    <p class="subtitle">{{ 'COMMUNICATION.UNAUTHORIZED' | translate }}</p>
  </div>
</div>
<app-flow-error *ngIf="globalError" [error]="globalError" />
