import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from '../../../../../core/services/error.service';
import { UserStore } from '../../../../../stores/user.store';
import { ContextStore } from '../../../../../stores/context.store';
import { ResourcesStore } from '../../../../../stores/resources.store';
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent {
  readonly userStore = inject(UserStore);
  readonly contextStore = inject(ContextStore);
  readonly resourcesStore = inject(ResourcesStore);

  readonly router = inject(Router);
  readonly route = inject(ActivatedRoute);
  //LEGACY
  readonly error = inject(ErrorService);

  id: string;

  constructor() {
    this.route.params.subscribe(async params => {
      this.id = params.id;
      if (this.id) await this.userStore.getClubOrderById(this.id);
    });
  }

  async backToOrders() {
    await this.router.navigate(['orders'], { replaceUrl: true });
  }
}
