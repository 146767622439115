<div class="pink-area m-fullwidth my-communications">
  <h1>{{ 'PROFILE.COMMUNICATION.VICTORIA' | translate }}</h1>

  <div class="form-group row m-column flex m-ac-center">
    <label class="flex row checkbox path" *ngIf="RGPDData">
      <input type="checkbox" [(ngModel)]="RGPDData.isOptIn" (ngModelChange)="updateOptIn($event)" />
      <svg viewBox="0 0 28 28">
        <use xlink:href="#custom-checkbox"></use>
      </svg>
      {{ 'PROFILE.COMMUNICATION.VICTORIA.CONSENT' | translate }}
    </label>
  </div>

  <div class="warning flex row ai-center" *ngIf="!globalError && RGPDData && !RGPDData?.isOptIn && !noRGPDData">
    <div>
      <svg viewBox="0 0 28 28">
        <use xlink:href="#icon-warning"></use>
      </svg>
    </div>
    <div [innerHTML]="'PROFILE.COMMUNICATION.NOSUBSCRIBE' | translate"></div>
  </div>

  <h1 class="margin-top-50">{{ 'PROFILE.COMMUNICATION.DELEGATES' | translate }}</h1>
  <div *ngIf="RGPDData && RGPDData?.delegateConnections?.length">
    <p>{{ 'PROFILE.COMMUNICATION.DELEGATES.CONSENT' | translate }}</p>
    <ul class="flex column wrap">
      <li class="flex-3" *ngFor="let rgpdInfo of RGPDData?.delegateConnections">
        <label class="flex row checkbox path text-margin-bottom">
          <input type="checkbox" [(ngModel)]="rgpdInfo.isOptIn" (ngModelChange)="updateDelegateRGPD($event, rgpdInfo.delegateId)" />
          <svg viewBox="0 0 28 28">
            <use xlink:href="#custom-checkbox"></use>
          </svg>
          {{ rgpdInfo.delegateName }}
        </label>
      </li>
    </ul>
  </div>
  <p *ngIf="!RGPDData || !RGPDData?.delegateConnections?.length">{{ 'PROFILE.COMMUNICATION.NODELEGATES' | translate }}</p>
</div>
<app-flow-error *ngIf="globalError" [error]="globalError" />
