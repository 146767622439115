import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DemoRole } from '@victoria-company/agora-client';

@Injectable({
  providedIn: 'root',
})
export class ListingDemoService {
  constructor(private api: ApiService) {}

  getListingDemo(role: DemoRole, index: number, take: number, demoStatus: AllowedDemoRequestStatus = null, fromDate: Date = null, orderBy: 'Ascending' | 'Descending' = 'Ascending') {
    const client = this.api.getClientInstance();

    return client.api.user.demos.get({
      queryParameters: {
        role,
        demoStatus,
        skip: index * take,
        take,
        orderBy,
        fromDate: fromDate?.toISOString()?.split('T')[0],
      },
    });
  }

  getListingDemoSummary(fromDate: Date) {
    const futureFromDate = fromDate?.toISOString();
    const client = this.api.getClientInstance();
    return client.api.user.demos.statusCount.get({ queryParameters: { futureFromDate } });
  }
}

export type AllowedDemoRequestStatus = 'Past' | 'Present' | 'Future' | 'All';
