<div class="cta-container btn-create-document">
  <button class="secondary" (click)="navigateTo('create')">
    <label>{{ 'STAFF.MANAGE.' + category + '.CREATE' | translate }}</label>
    <svg class="icon peach" viewBox="0 0 20 20">
      <use xlink:href="#icon-cross" />
    </svg>
  </button>
</div>
<div class="documents-list-container">
  @for (document of documents(); track document) {
    <app-manage-document-card
      [visible]="isVisibleDocument(document.visibleFrom, document.visibleUntil)"
      [document]="document"
      (requestDeleteDocument)="openDeleteDocumentModal(document)"
      (requestOpenActionSheet)="openActionSheet(document)" />
  }
</div>
<app-confirmation-modal
  [title]="'DOCUMENT.MANAGER.DELETE.TITLE' | translate"
  [width]="'42rem'"
  [isOpened]="deleteConfirmationModalOpened"
  (cancelClicked)="closeDeleteDocumentModal()"
  (confirmClicked)="deleteDocument()">
  <p class="confirmation-modal-content">{{ getDeleteDocumentMessage() }}</p>
</app-confirmation-modal>
<app-actions-sheet *ngIf="actionsSheetOpened" (closeAction)="closeActionSheet()">
  <li>
    <ag-button
      leftIcon="#icon-edit-adress"
      data-testId="add_client"
      iconClasses="leftIcon"
      [label]="'GLOBAL.ACTIONS.MODIFY' | translate"
      classes="btn-white"
      (triggerFunction)="navigateTo('edit', currentDocument.id)"></ag-button>
  </li>
  <li>
    <ag-button
      leftIcon="#icon-trash"
      data-testId="add_comment"
      iconClasses="leftIcon"
      [label]="'GLOBAL.ACTION.REMOVE' | translate"
      classes="btn-white"
      (triggerFunction)="openDeleteDocumentModal(currentDocument)"></ag-button>
  </li>
</app-actions-sheet>
<app-flow-error *ngIf="globalError" [error]="globalError" />
