import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import {
  CartResponse,
  GetCartAvailablePaymentTypesResponse_PaymentOption,
  GetUserAdvantagesResponse_VoucherResponse,
  PaymentResponseStatus,
  PaymentResponseStatusObject,
  PaymentType,
} from '@victoria-company/agora-client';
import { CheckoutType } from '../../../../../../core/enums/checkout-type.enum';
import { AddAdvantageStatus } from '../../../../../../state/models/addAvantageStatus';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesStore } from '../../../../../../stores/resources.store';

@Component({
  selector: 'app-order-payment',
  templateUrl: './order-payment.component.html',
  styleUrls: ['./order-payment.component.scss'],
})
export class OrderPaymentComponent {
  //V2
  public readonly CheckoutType = CheckoutType;
  private activeRoute = inject(ActivatedRoute);
  private router = inject(Router);

  @Input() cart: CartResponse;
  @Input() checkoutType: CheckoutType;
  @Input() internalPaymentType: PaymentType;
  @Input() paymentOptions: GetCartAvailablePaymentTypesResponse_PaymentOption[];
  //SubComponent needed infos
  @Input() vouchers: GetUserAdvantagesResponse_VoucherResponse[] = [];
  @Input() vouchersInCart: GetUserAdvantagesResponse_VoucherResponse[] = [];
  @Input() walletAvailableAmount: number;
  @Input() voucherEncodingStatus: AddAdvantageStatus;
  @Input() voucherSelectionStatus: AddAdvantageStatus;
  @Input() walletConsumptionStatus: AddAdvantageStatus;
  //
  @Output() requestSaveInternalPaymentType = new EventEmitter<PaymentType>();
  @Output() requestValidateEncodedVoucherCode = new EventEmitter<string>();
  @Output() requestUseVoucher = new EventEmitter<string>();
  @Output() requestUpdateWalletAmount = new EventEmitter();
  @Output() requestResetStatuses = new EventEmitter();
  @Output() requestSignDip = new EventEmitter();

  resourcesStore = inject(ResourcesStore);
  paymentError: PaymentResponseStatus;

  ngOnInit() {
    this.catchPaymentStateAndCleanUrl();
  }

  private catchPaymentStateAndCleanUrl() {
    this.activeRoute.queryParams.subscribe(params => {
      if (params.p) {
        this.paymentError = params.p;
        this.router.navigate([], {
          relativeTo: this.activeRoute,
          queryParams: { p: null },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  protected readonly PaymentResponseStatusObject = PaymentResponseStatusObject;
}
