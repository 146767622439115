import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MD5 } from 'object-hash';
import { ResourcesStore } from '../../../../stores/resources.store';
import { WishlistStore } from '../../../../stores/wishlist.store';
import { UserStore } from '../../../../stores/user.store';
import { CartStore } from '../../../../stores/cart.store';
import { ContextStore } from '../../../../stores/context.store';
import { BreadcrumbService } from '../../../../core/services/breadcrumb.service';
import { ProductSearchModel } from '@victoria-company/agora-client';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss'],
})
export class WishlistComponent {
  readonly router = inject(Router);

  public resourcesStore = inject(ResourcesStore);
  public wishlistStore = inject(WishlistStore);
  public userStore = inject(UserStore);
  public cartStore = inject(CartStore);
  private contextStore = inject(ContextStore);
  readonly breadcrumb = inject(BreadcrumbService);

  async navigateToCollection() {
    if (this.cartStore.demo()?.demo && this.cartStore.demo()?.roles.includes('Attendee')) {
      await this.router.navigateByUrl(`/${this.contextStore.locale()}/myvictoria/demo/${this.cartStore.demo()?.demo?.code}/jewelry`);
    } else {
      window.open(this.resourcesStore.getEshopCollectionLink());
    }
  }

  goToExternalCollection() {
    return this.resourcesStore.getEshopCollectionLink();
  }

  goToInternalCollection() {
    return `/${this.contextStore.locale()}/myvictoria/demo/${this.cartStore.demo()?.demo?.code}/jewelry`;
  }

  trackByItems(_: number, item: ProductSearchModel): string {
    return MD5(item);
  }

  addToCart(product: ProductSearchModel) {
    this.cartStore.openAddToCartVariantSelectionModal(product);
  }

  deleteFromWishlist(productId: string) {
    this.wishlistStore.openDeleteItemFromWishlistConfirmationModal(productId);
  }

  async navigateToProduct(product: ProductSearchModel) {
    if (this.cartStore.demo()?.demo && (this.cartStore.demo()?.demo?.status == 'Opened' || this.cartStore.demo()?.demo?.status == 'PostSale') && this.cartStore.demo()?.roles.includes('Attendee')) {
      await this.router.navigateByUrl(`/${this.contextStore.locale()}/myvictoria/demo/${this.cartStore.demo()?.demo?.code}/jewelry/${product?.id}`);
    } else {
      const url = this.resourcesStore.getEshopProductCollectionLink(product);
      window.location.href = url;
    }
  }
}
