import { ApiService } from './api.service';
import { inject, Injectable } from '@angular/core';
import { GdprOptInSource } from '@victoria-company/agora-client';

@Injectable({
  providedIn: 'root',
})
export class RGPDService {
  private api = inject(ApiService);

  //Connected User GDPR Call
  public async getRGPDataForClient() {
    const client = this.api.getClientInstance();
    return client.api.user.gdpr.get();
  }

  public async setDelegateConnection(delegateId: string) {
    const client = this.api.getClientInstance();
    return client.api.user.gdpr.delegates.byDelegateId(delegateId).subscribe.post({});
  }

  public async unsetDelegateConnection(delegateId: string) {
    const client = this.api.getClientInstance();
    return client.api.user.gdpr.delegates.byDelegateId(delegateId).unSubscribe.post({});
  }

  public async setOptIn(optInSource: GdprOptInSource) {
    const client = this.api.getClientInstance();
    return client.api.user.gdpr.transactional.subscribe.post({ optInSource });
  }

  public async unsetOptIn() {
    const client = this.api.getClientInstance();
    return client.api.user.gdpr.transactional.unSubscribe.post({});
  }

  //Anonymous User GDPR Call With GdprId
  public async getRGPDDataForVisitor(gdprId: string) {
    const client = this.api.getClientInstance();
    return client.api.public.gdpr.byGdprId(gdprId).get();
  }

  public async setVisitorDelegateConnection(gdprId: string, delegateId: string) {
    const client = this.api.getClientInstance();
    return client.api.public.gdpr.byGdprId(gdprId).delegates.byDelegateId(delegateId).subscribe.post();
  }

  public async unsetVisitorDelegateConnection(gdprId: string, delegateId: string) {
    const client = this.api.getClientInstance();
    return client.api.public.gdpr.byGdprId(gdprId).delegates.byDelegateId(delegateId).unSubscribe.post();
  }

  public async setVisitorOptIn(gdprId: string, optInSource: GdprOptInSource) {
    const client = this.api.getClientInstance();
    return client.api.public.gdpr.byGdprId(gdprId).transactional.subscribe.post({ optInSource });
  }

  public async unsetVisitorOptIn(gdprId: string) {
    const client = this.api.getClientInstance();
    return client.api.public.gdpr.byGdprId(gdprId).transactional.unSubscribe.post();
  }
}
