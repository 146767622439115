<div class="status-datas-container">
  <div class="error-box" *ngIf="showError">
    <div class="flex row ai-center">
      <svg viewBox="0 0 28 28" class="icon-warning">
        <use xlink:href="#icon-warning"></use>
      </svg>
      <div>
        <span>{{ 'ERROR.TITLE' | translate }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="clubInformation && trackableActions?.length">
    <div class="d-only">
      <table>
        <tr *ngFor="let dataRow of trackableActions">
          <td class="image">
            @switch (dataRow.actionType) {
              @case (ClubActionTypeEnumObject.FollowFacebook) {
                <svg><use xlink:href="#card-fb"></use></svg>
              }
              @case (ClubActionTypeEnumObject.FollowInstagram) {
                <svg><use xlink:href="#card-ig"></use></svg>
              }
              @case (ClubActionTypeEnumObject.BoosterLevel1) {
                <svg><use xlink:href="#icon-group"></use></svg>
              }
              @default {
                <img loading="lazy" src="assets/images/tips-{{ getTrackableActionTypeToLegacyId(dataRow.actionType) }}.png" />
              }
            }
          </td>
          <td class="description">
            <h3>{{ 'STATUS.MOREPOINTS.' + getTrackableActionTypeToLegacyText(dataRow.actionType) + '.TITLE' | translate }}</h3>
            <p>{{ 'STATUS.MOREPOINTS.' + getTrackableActionTypeToLegacyText(dataRow.actionType) + '.LABELXPOINTS' | translate: { X: dataRow.referenceValue } }}</p>
          </td>
          <td class="received">
            <div class="flex j-center">
              <h2>
                @if (dataRow.actionType == ClubActionTypeEnumObject.CodeAdd) {
                  {{ 'GLOBAL.ADDONEPOINTEURO' | translate }}
                } @else if (dataRow.actionType == ClubActionTypeEnumObject.BoosterLevel1) {
                  {{ 'GLOBAL.ADDXPOINTS' | translate: { X: 275 } }}
                  <br /><br />
                  {{ 'GLOBAL.ADDXEUROJEWELS' | translate: { X: 100 | agoraCurrencyInt } }}
                } @else if (dataRow.referenceValue) {
                  {{ 'GLOBAL.ADDXPOINTS' | translate: { X: dataRow.referenceValue?.toFixed(0) } }}
                }
              </h2>
            </div>
          </td>
          <td class="action-button">
            @if (dataRow.doneDate) {
              <div>
                <svg viewBox="0 0 32 32" class="icon peach">
                  <use xlink:href="#icon-checkmark"></use>
                </svg>
                &nbsp; {{ 'STATUS.MOREPOINTS.ACTION.DONEATDATEX' | translate: { X: dataRow.doneDate | date: 'dd/MM/yyyy' } }}
              </div>
            } @else {
              <div>
                <button class="btn btn-primary m-j-center m-fit-content block m-auto-no-margin" (click)="handleTrackableAction(dataRow.actionType)">
                  <div>
                    <label>
                      @switch (dataRow.actionType) {
                        @case (ClubActionTypeEnumObject.CodeAdd) {
                          {{ 'STATUS.MOREPOINTS.BUY.ACTION' | translate }}
                        }
                        @case (ClubActionTypeEnumObject.FollowFacebook) {
                          {{ 'STATUS.MOREPOINTS.SOCIAL.ACTION' | translate }}
                        }
                        @case (ClubActionTypeEnumObject.FollowInstagram) {
                          {{ 'STATUS.MOREPOINTS.SOCIAL.ACTION' | translate }}
                        }
                        @case (ClubActionTypeEnumObject.BoosterLevel1) {
                          {{ 'STATUS.MOREPOINTS.RECOMMANDATION.ACTION' | translate }}
                        }
                        @case (ClubActionTypeEnumObject.BirthdayAdd) {
                          {{ 'STATUS.MOREPOINTS.BIRTHDAY.ACTION' | translate }}
                        }
                        @case (ClubActionTypeEnumObject.NewsletterSubscription) {
                          {{ 'STATUS.MOREPOINTS.NEWSLETTER.ACTION' | translate }}
                        }
                        @default {
                          {{ 'UNKNOWN' }}
                        }
                      }
                    </label>
                  </div>
                </button>
              </div>
            }
          </td>
        </tr>
      </table>
    </div>
    <div class="m-only">
      <app-carousel class="m-only carousel">
        <div class="flex" *ngFor="let dataRow of trackableActions">
          <div class="card center flex-1">
            <div class="header">
              @switch (dataRow.actionType) {
                @case (ClubActionTypeEnumObject.FollowFacebook) {
                  <svg><use xlink:href="#card-fb"></use></svg>
                }
                @case (ClubActionTypeEnumObject.FollowInstagram) {
                  <svg><use xlink:href="#card-ig"></use></svg>
                }
                @case (ClubActionTypeEnumObject.BoosterLevel1) {
                  <svg><use xlink:href="#icon-group"></use></svg>
                }
                @default {
                  <img loading="lazy" src="assets/images/tips-{{ getTrackableActionTypeToLegacyId(dataRow.actionType) }}.png" />
                }
              }
            </div>
            <div class="body">
              <div class="container">
                <p class="title">{{ 'STATUS.MOREPOINTS.' + getTrackableActionTypeToLegacyText(dataRow.actionType) + '.TITLE' | translate }}</p>
                <p class="description">{{ 'STATUS.MOREPOINTS.' + getTrackableActionTypeToLegacyText(dataRow.actionType) + '.LABELXPOINTS' | translate: { X: dataRow.referenceValue } }}</p>
                <p class="received">
                  @if (dataRow.actionType == ClubActionTypeEnumObject.CodeAdd) {
                    {{ 'GLOBAL.ADDONEPOINTEURO' | translate }}
                  } @else if (dataRow.actionType == ClubActionTypeEnumObject.BoosterLevel1) {
                    {{ 'GLOBAL.ADDXPOINTS' | translate: { X: 275 } }}
                    <br /><br />
                    {{ 'GLOBAL.ADDXEUROJEWELS' | translate: { X: 100 | agoraCurrencyInt } }}
                  } @else {
                    {{ 'GLOBAL.ADDXPOINTS' | translate: { X: dataRow.referenceValue } }}
                  }
                </p>
              </div>
            </div>
            <div class="footer">
              @if (dataRow.doneDate) {
                <div class="flex actionDone">
                  <svg viewBox="0 0 32 32" class="icon peach height100">
                    <use xlink:href="#icon-checkmark"></use>
                  </svg>
                  &nbsp; {{ 'STATUS.MOREPOINTS.ACTION.DONEATDATEX' | translate: { X: dataRow.doneDate | date: 'dd/MM/yyyy' } }}
                </div>
              } @else {
                <div>
                  <button class="btn btn-primary m-j-center m-fit-content block m-auto-no-margin flex center-all" (click)="handleTrackableAction(dataRow.actionType)">
                    <div>
                      <label>
                        @switch (dataRow.actionType) {
                          @case (ClubActionTypeEnumObject.CodeAdd) {
                            {{ 'STATUS.MOREPOINTS.BUY.ACTION' | translate }}
                          }
                          @case (ClubActionTypeEnumObject.FollowFacebook) {
                            {{ 'STATUS.MOREPOINTS.SOCIAL.ACTION' | translate }}
                          }
                          @case (ClubActionTypeEnumObject.FollowInstagram) {
                            {{ 'STATUS.MOREPOINTS.SOCIAL.ACTION' | translate }}
                          }
                          @case (ClubActionTypeEnumObject.BoosterLevel1) {
                            {{ 'STATUS.MOREPOINTS.RECOMMANDATION.ACTION' | translate }}
                          }
                          @case (ClubActionTypeEnumObject.BirthdayAdd) {
                            {{ 'STATUS.MOREPOINTS.BIRTHDAY.ACTION' | translate }}
                          }
                          @case (ClubActionTypeEnumObject.NewsletterSubscription) {
                            {{ 'STATUS.MOREPOINTS.NEWSLETTER.ACTION' | translate }}
                          }
                          @default {
                            {{ 'UNKNOWN' }}
                          }
                        }
                      </label>
                    </div>
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </app-carousel>
    </div>
  </div>
</div>

<app-modal [id]="'birthDateModal'" template="birthDateModal" modalClass="birthDateModal"></app-modal>
<app-flow-error *ngIf="globalError" [error]="globalError" />
