<div *ngIf="resourcesStore.isLoaded()" class="flex column order-contact">
  <div class="order-demo-code flex ai-center j-center ac-center" *ngIf="!checkoutStore.cart()?.demoId && !this.checkoutStore.demo()">
    <div class="label">
      {{ resourcesStore.i18n().resources.checkoutConsultant.ctaMessage | removeHtmlTag }}
    </div>
    <div class="link">
      <a (click)="openEnterDemoCodeModal()" class="ctaLink">{{ resourcesStore.i18n().resources.checkoutConsultant.buttonLabel }}</a>
    </div>
  </div>

  <div *ngIf="checkoutStore.delegate()" class="order-contact-container">
    <app-order-contact-box
      [delegate]="checkoutStore.delegate()"
      [demo]="checkoutStore.demo()"
      [profile]="userStore.profile()"
      [resources]="resourcesStore.i18n().resources"
      [cart]="checkoutStore.cart()"
      (requestOpenSearchDelegateModal)="openSearchDelegateModal()"
      (requestUpdateCartDelegate)="updateCartDelegate(checkoutStore.delegate())"></app-order-contact-box>
  </div>

  <div *ngIf="error.lookupError$ | async" class="order-contact-container">
    <h1 class="canela">{{ resourcesStore.i18n().resources.checkoutConsultant.secondTitle }}</h1>
    <div class="flex column">
      <p class="subtitle">{{ resourcesStore.i18n().resources.checkoutConsultant.addressLabelError }}</p>
      <div class="order-contact-info flex column ai-center">
        {{ 'ORDER_TUNNEL.CONTACT.MISSING_INFO' | translate }}
        <div class="flex j-center rowCTA">
          <app-wavedlink [linkText]="resourcesStore.i18n().resources.checkoutConfirmation.loginButtonLabel" [linkUrl]="'/' + contextStore.locale() + '/club/profile/data'"></app-wavedlink>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!resourcesStore.isLoaded()" class="flex loader-container center-all">
  <div class="loader"></div>
</div>

<app-error-box *ngIf="error.delegateProfileError$ | async"></app-error-box>

<!--<app-modal *ngIf="resources" id="orderSelectCode" [title]="resources.popupFindHomeparty.mainTitle"-->
<!--           template="addCode"></app-modal>-->
<!--<app-modal *ngIf="resources"  id="orderSearchDelegate" [title]="resources.popupFindConsultant.mainTitle" template="searchDelegate"></app-modal>-->
<!--<app-modal *ngIf="resources" id="orderSelectDelegate" [title]="resources.popupFindConsultant.chooseConsultantTitle"-->
<!--           template="selectDelegate" [item]="delegatesToSelect"></app-modal>-->

<app-search-delegate-modal
  (requestCloseSearchDelegateModal)="closeSearchDelegateModal()"
  (requestSearchDelegate)="searchDelegateByForm($event)"
  [data]="checkoutStore.searchDelegateModal().data"
  [isOpened]="checkoutStore.searchDelegateModal().isOpened"></app-search-delegate-modal>
<app-encoding-demo-code-modal
  (requestCloseEnterDemoCodeModal)="closeEnterDemoCodeModal()"
  [isOpened]="checkoutStore.enterDemoCodeModal().isOpened"
  [demoCode]="checkoutStore.enterDemoCodeModal().demoCode"
  [error]="checkoutStore.enterDemoCodeModal().error"></app-encoding-demo-code-modal>
<app-select-delegate-modal
  (requestCloseSelectDelegateModal)="closeSelectDelegateModal()"
  (requestSelectDelegate)="setDelegateSelected($event)"
  [isOpened]="checkoutStore.foundDelegatesModal().isOpened"
  [delegates]="checkoutStore.foundDelegatesModal().delegates"></app-select-delegate-modal>
