<a class="back-to-orders" [routerLink]="'/' + contextStore.locale() + '/' + displayBackToOrders.link" *ngIf="displayBackToOrders">
  <!--  <div class="icon icon-left peach">
      <svg viewBox="0 0 32 32" class="menu-carret-icon">
        <use xlink:href="#icon-arrow-left-long"></use>
      </svg>
    </div>
    <div class="label">{{displayBackToOrders.title}}</div>-->
  <div class="flex ai-center">
    <svg viewBox="0 0 32 32" class="icon peach">
      <use xlink:href="#icon-arrow-left-long"></use>
    </svg>
    &nbsp; {{ displayBackToOrders.title }}
  </div>
</a>

<div class="order-container" *ngIf="order && resourcesStore.isLoaded()">
  <div class="order-datas j-space-between">
    <div>
      <p>
        {{ 'ORDER.DATE' | translate }}: <span class="date right-align">{{ order.orderDate | date: 'dd/MM/yyyy' }}</span>
      </p>
      <p>
        {{ 'ORDER.STATUS' | translate }}: <span class="status right-align">{{ 'GLOBAL.ORDER.STATUS' + toOrderStatusId(order.status) | translate }}</span>
      </p>
      <div *ngIf="order.demoId" class="d-only order-inline-line gap-10 flex ai-center">
        <div class="icon-party-order flex center-all">
          <svg viewBox="0 0 32 32" class="icon">
            <use xlink:href="#icon-party"></use>
          </svg>
        </div>
        <span>{{ 'ORDER.VIEW.ORIGINE.DEMO' | translate }}</span>
      </div>
    </div>
    <div>
      <p class="flex j-space-between">
        {{ 'ORDER.TOTAL' | translate }}: <span class="amount date right-align">{{ order.total | agoraCurrency }}</span>
      </p>
      <p class="flex j-space-between">
        {{ 'ORDER.DELIVERY' | translate }}:
        <span class="delivery date right-align" *ngIf="hasOnlyGift()">{{ 'FIDELITY.DELIVERY.EMAIL' | translate }}</span>
        <span class="delivery date right-align" *ngIf="!hasOnlyGift()">{{ 'ORDER.DELIVERY.ID' + toDeliveryId(order.deliveryType) | translate }}</span>
      </p>
      <div *ngIf="order.demoId" class="m-only order-inline-line gap-10 flex ai-center">
        <div class="icon-party-order flex center-all">
          <svg viewBox="0 0 32 32" class="icon">
            <use xlink:href="#icon-party"></use>
          </svg>
        </div>
        <span>{{ 'ORDER.VIEW.ORIGINE.DEMO' | translate }}</span>
      </div>
    </div>
  </div>
    <div class="order-delegate" *ngIf="order.delegate">
      <h1>{{ 'ORDER.DELEGATE' | translate }} :</h1>
      <div class="order-delegate-datas">
        <div class="image">
          <img loading="lazy" [src]="getProfilePictureOrDefault(order.delegate.profileImage)" [alternativeImage] />
        </div>
        <div class="data-1">
          <p>{{ order.delegateName }}</p>
          <div class="flex delegate-datas">
            <div class="flex el ai-center" *ngIf="order.delegate.mobile">
              <svg viewBox="0 0 32 32" class="icon peach">
                <use xlink:href="#icon-telephone"></use>
              </svg>
              &nbsp; <a href="tel:{{ order.delegate.mobile }}">{{ order.delegate.mobile }}</a>
            </div>
            <div class="flex el ai-center" *ngIf="order.delegate.email">
              <svg viewBox="0 0 32 32" class="icon peach">
                <use xlink:href="#icon-newsletter"></use>
              </svg>
              &nbsp; <a href="mailto:{{ order.delegate.email }}">{{ order.delegate.email }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class="order-articles">
    <h1>{{ 'ORDER.ARTICLES' | translate }} :</h1>
    <div class="order-articles-datas">
      <div class="order-articles-table">
        <div *ngIf="order.orderItems">
          <div *ngFor="let orderItem of order.orderItems; index as i" class="order-item">
            <app-view-order-item [item]="orderItem" [border]="order.orderItems.length !== i + 1"  (requestZoomInImage)="openZoomModal($event)" />
          </div>
        </div>
        <div class="order-payment-details">
          <h1>{{ 'GLOBAL.ORDER.AMOUNT_DETAILS' | translate }}:</h1>
          <div>
            <div class="infos flex row j-space-between">
              <p class="title">{{ resourcesStore.i18n()?.resources.shoppingBag.subtotal }}:</p>
              <p>{{ order.itemsTotal | agoraCurrency }}</p>
            </div>
            <div class="codes-list" *ngIf="order.orderVouchers?.length">
              <div class="infos flex row j-space-between">
                <p class="title">{{ 'ORDER_TUNNEL.SUMMARY.VOUCHERS' | translate }}</p>
                <p>-{{ order.vouchersTotal | agoraCurrency }}</p>
              </div>
              <div>
                <p *ngFor="let voucher of order.orderVouchers" class="code flex j-space-between">
                 <span>{{ 'GLOBAL.VOUCHER.TYPE_' + voucher.type.toUpperCase() | translate }} - {{ voucher.code }}</span> <span>-{{ voucher.amount | agoraCurrency }}</span>
                </p>
              </div>
            </div>
            <div class="infos flex row j-space-between" *ngIf="order.walletAmount > 0">
              <p class="title">{{ 'CART.SUMMARY.TOTALWALLET' | translate }}</p>
              <p>-{{ order.walletAmount | agoraCurrency }}</p>
            </div>
            <div class="infos flex row j-space-between">
              <p class="title">{{ 'ORDER.DELIVERY.LABEL' | translate }}:</p>
              <p>{{ order.deliveryFee | agoraCurrency }}</p>
            </div>
            <div class="infos flex row j-space-between">
              <p class="title">{{ 'GLOBAL.ORDER.TOTALPAID' | translate }}:</p>
              <p>{{ order.totalPaid | agoraCurrency }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="help-box">
        <div class="help">
          <div class="box">
            <h1>{{ 'ORDER.HELP.TITLE' | translate }}</h1>
            <p>{{ 'ORDER.HELP.SUBTITLE' | translate }}</p>
            <h2>{{ 'ORDER.HELP.TITLE2' | translate }}</h2>
            <p>{{ 'ORDER.HELP.WARRANTY.LABEL' | translate }}</p>
            <a [href]="filter(resourcesStore.i18n()?.footer.footerNav[3].children[0].navLink)">{{ 'ORDER.HELP.WARRANTY' | translate }}</a>
            <p>{{ 'ORDER.HELP.TAKECARE.LABEL' | translate }}</p>
            <a [href]="filter(resourcesStore.i18n()?.footer.footerNav[3].children[1].navLink)">{{ 'ORDER.HELP.TAKECARE' | translate }}</a>
            <p>{{ 'ORDER.HELP.RETURN.LABEL' | translate }}</p>
            <a [href]="filter(resourcesStore.i18n()?.footer.footerNav[3].children[2].navLink)">{{ 'ORDER.HELP.RETURN' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-zoom-in-picture-modal [opened]="zoomModalOpened" [imageUrl]="zoomModalImage" (requestCloseModal)="closeZoomModal()" />
