<div class="flex column gap-20">
  <app-optin-dip-box [cart]="cart" (requestSignDip)="requestSignDip.emit()"></app-optin-dip-box>
  <div class="order-payment" *ngIf="cart && resourcesStore.isLoaded()" [class.cancel-gap-20-with-margin]="!cart?.preContractualInformationDocument?.required">
    <div class="pink-separator">
      <div *ngIf="paymentError" class="order-payment-error">
        <div class="flex row ai-center">
          <svg viewBox="0 0 28 28" class="icon-warning">
            <use xlink:href="#icon-warning"></use>
          </svg>
          <div>
            <span *ngIf="paymentError == PaymentResponseStatusObject.Canceled">{{ resourcesStore.i18n().resources.checkoutPayment.messageLabelError }}</span>
            <span *ngIf="paymentError == PaymentResponseStatusObject.Failed">{{ resourcesStore.i18n().resources.checkoutPayment.topicLabelError }}</span>
            <span *ngIf="paymentError == PaymentResponseStatusObject.Expired">{{ resourcesStore.i18n().resources.resetpassword.shortDescription }}</span>
            <span *ngIf="paymentError == PaymentResponseStatusObject.Unknown">{{ 'ALERT.ERROR.GLOBAL' | translate }}</span>
          </div>
        </div>
      </div>

      <h1 class="canela">{{ 'ORDER_TUNNEL.REDUCTIONS.TITLE' | translate }}</h1>
      <p class="description">{{ 'ORDER_TUNNEL.REDUCTIONS.CAPTION' | translate }}</p>

      <div class="flex column gap-8 avantages-box">
        <app-voucher-encoding
          [cart]="cart"
          [stateStatus]="voucherEncodingStatus"
          (requestValidateEncodedVoucherCode)="requestValidateEncodedVoucherCode.emit($event)"
          (requestResetStatuses)="requestResetStatuses.emit()"></app-voucher-encoding>
      </div>

      <div class="flex column gap-8 avantages-box" *ngIf="vouchers && vouchers?.length > 0">
        <app-voucher-selection
          [cart]="cart"
          [stateStatus]="voucherSelectionStatus"
          [vouchers]="vouchers"
          [vouchersInCart]="vouchersInCart"
          (requestUseVoucher)="requestUseVoucher.emit($event)"
          (requestResetStatuses)="requestResetStatuses.emit()"></app-voucher-selection>
      </div>

      <div class="flex column gap-8 avantages-box" *ngIf="walletAvailableAmount > 0 && (cart.walletAmount <= 0 ? cart.vouchersTotal < cart.total : true)">
        <app-wallet-consumption
          [cart]="cart"
          [stateStatus]="walletConsumptionStatus"
          [walletAvailableAmount]="walletAvailableAmount"
          (requestUpdateWalletAmount)="requestUpdateWalletAmount.emit($event)"></app-wallet-consumption>
      </div>
    </div>
    <div class="choice-of-payment" *ngIf="cart.totalToPay > 0">
      <app-payment-mode-selection
        [cart]="cart"
        [internalPaymentType]="internalPaymentType"
        [paymentOptions]="paymentOptions"
        (requestSaveInternalPaymentType)="requestSaveInternalPaymentType.emit($event)"></app-payment-mode-selection>
    </div>
  </div>
</div>
